import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-label"
}
const _hoisted_2 = { class: "radio-buttons" }
const _hoisted_3 = ["id", "name", "value"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['radio-group', _ctx.customClass])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "radio-button"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: `${_ctx.name}${index + 1}`,
            name: _ctx.name,
            value: option?.value ?? option,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event))
          }, null, 8, _hoisted_3), [
            [_vModelRadio, _ctx.selectedValue]
          ]),
          _createElementVNode("label", {
            class: "body-text",
            for: `${_ctx.name}${index + 1}`
          }, _toDisplayString(option.value), 9, _hoisted_4)
        ]))
      }), 128))
    ])
  ], 2))
}