<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper">
            <div class="card-header">
                <h5>Plan COBRA Details</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>

            <!-- General Plan Detail Cards -->
            <div class="plan-general-details card card-toggle">
                <div class="plan-general-detail-container card" v-if="dataLoaded">
                    <div class="info-card__icon">
                        <i class="iatse-icon-user-shield"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5>Participant ID</h5>
                        <h4>{{ planSummary.Person.PersonID }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-calendar-payment-loan"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5>Name</h5>
                        <h4>{{ planSummary.Person.Name }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-donate"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5>Date of Birth</h5>
                        <h4>{{ planSummary.Person.DateOfBirth }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-money-check-edit"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5>Gender</h5>
                        <h4>{{ planSummary.Person.Gender }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-legal"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5>Address</h5>
                        <h4>{{ planSummary.Person.Address }}</h4>
                    </div>
                </div>
            </div>

            <!-- COBRA Details 1st row -->
            <div class="plan-general-details card card-toggle">
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-time-quarter"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info">Plan</div>
                        <h4>{{ planSummary.Coverage.Plan }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-person-circle-xmark"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"><h5>Status </h5></div>
                        <h4>{{ 'N/A' }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-together-people"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"> <h5>Event</h5></div>
                        <h4>{{ 'N/A' }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-person-circle-xmark"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"> <h5>COBRA Effective</h5></div>
                        <h4>{{ planSummary.Coverage.EffectiveDate }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-hr-person"></i>
                    </div>
                    <div class="plan-general-detail mr-10">
                        <div class="plan-detail-info"> <h5>Event Date</h5></div>
                        <h4></h4>
                    </div>
                </div>
            </div>

            <!-- COBRA Details 2nd row -->
            <div class="plan-general-details card card-toggle">
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-time-quarter"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info">Max Term Date</div>
                        <h4>{{ planSummary.Coverage.ExpirationDate }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-person-circle-xmark"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"><h5>Months Entitled</h5></div>
                        <h4>{{ planSummary.Coverage.MonthsEntitled }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-together-people"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"> <h5>Months elapsed</h5></div>
                        <h4>{{ planSummary.Coverage.ElapsedMonths }}</h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-person-circle-xmark"></i>
                    </div>
                    <div class="plan-general-detail">
                        <div class="plan-detail-info"> <h5>Months remaining</h5></div>
                        <h4>{{ planSummary.Coverage.RemainingMonths }}</h4>
                    </div>
                </div>
            </div>
        </div>

        <CobraPayments :cobraPayments="planSummary.CobraInvoices" :showMore="true" :title="title"/>

        <!-- Account Actions -->
        <div class="plan-summary__footer row w-100">
            <div class="col-12 col-md-4">
                <h4>Other Coverages</h4>
                <div class="account-actions">
                    <!-- Other Coverages -->
                    <div class="action-buttons">
                        
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <h4>Account Actions</h4>
                <div class="action-buttons mt-10">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('beneficiaries', 'My Beneficiaries', 'plan-tables-striped beneficiary', null, null, null
                        )">
                        Beneficiaries
                    </a>
                    <a 
                        href="#"
                        class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">
                        Claims
                    </a>
                    <a href="" class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('dependents', 'My Dependents', null)">My Dependents
                    </a>
                </div>
            </div>
        </div> <!-- end actions -->
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import CobraPayments from '@/components/participant/health-welfare/CobraPayments.vue';
import { useParticipantStore } from '@/stores/participant';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { PlanCobraSummary } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const participantStore = useParticipantStore();
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const coverageStore = useCoverageStore();
        const dashboardItems = computed(() => participantStore.dashboardItems);
        const title = 'Current COBRA - Payment History';

        const planSummary = ref<PlanCobraSummary> ({
            Person: {
                PersonID: 0,
                Name: '',
                DateOfBirth: '',
                Gender: '',
                Address: ''
            },
            Coverage: {
                From: '',
                To: '',
                PlanClass: null,
                Plan: ''
            },
            CobraPayments: []
        });

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = () => {
            const params: Record<string, any> = {};

            axios
                .get('/api/participant/health-welfare/plan-cobra-summary', { params })
                .then((response) => {
                    // Store other plan summary details
                    planSummary.value = {
                        ...response.data
                    };
                    dataLoaded.value = true;

                     // Check if coverage data is present in the store
                    // if (coverageStore.coverage) {
                    //     planSummary.value.Coverage = coverageStore.coverage;
                    // }
                    // else {
                    //     // If not already store we fetch coverage data again, overall this shouldn't happen
                    //     params.FetchCoverage = true;
                    // }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };


        onMounted(() => {
            fetchData(); // Initial fetch on mount
        });

        return {
            openModal,
            title,
            dataLoaded,
            formatCurrency,
            planSummary,
            dashboardItems
        };
    },

    components: {
        CobraPayments
    }
};
</script>