// src/stores/coverage.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { CoverageDetails } from '@/interfaces/health';

export const useCoverageStore = defineStore('coverage', () => {
	const coverage = ref < CoverageDetails | null > (null);
    const isAvailableForClaim = ref(false);
	const loading = ref(false);
	const error = ref < Error | null > (null);

	const fetchCoverageData = async () => {
		if (coverage.value) {
			// Data is already loaded; no need to fetch again
			return;
		}

		loading.value = true;
		error.value = null;

		// try {
		// 	const response = await axios.get('/api/participant/dashboard/coverage');
		// 	coverage.value = response.data.Coverage;
		// } catch (err) {
		// 	error.value = err as Error;
		// 	console.error('Error fetching coverage data:', error.value);
		// } finally {
		// 	loading.value = false;
		// }
	};

	const clearCoverageData = () => {
		coverage.value = null;
	};

	return {
		// State
		coverage,
        isAvailableForClaim,
		loading,
		error,
		// Actions
		fetchCoverageData,
		clearCoverageData,
	};
});