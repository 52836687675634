import { defineStore } from "pinia";
import { useProfileStore } from "@/stores/profile";
import axios from "axios";
import router from "@/router";
import { useParticipantStore } from "./participant";

interface Credentials {
    email: string;
    password: string;
}

interface User {
    name: string;
    roleName: string;
    unreadAlerts: number;
    email: string;
    participantId: string | number;
}

export const useAuthStore = defineStore({
    id: "auth",

    // state: () => ({
    //   token: localStorage.getItem('authToken') as string | null,
    //   user: null as User | null
    // }),

    state: () => {
        const isImpersonatingStorage = localStorage.getItem("isImpersonating");
        const isImpersonating = isImpersonatingStorage === "1";

        return {
            token: localStorage.getItem("authToken") as string | null,
            stopImpersonationRoute: "superadmin.users.participants",
            isImpersonating: isImpersonating,
            impersonationToken: localStorage.getItem("impersonationToken") as
                | string
                | null,
            impersonationName: localStorage.getItem(
                "impersonationName"
            ) as string,
            user: JSON.parse(
                localStorage.getItem("user") || "null"
            ) as User | null,
        };
    },

    getters: {
        isAuthenticated: (state) => {
            return !!state.token;
        },
        isEmployer: (state) => {
            const employerRoles = [
                "ROLE_EMPLOYER",
                "ROLE_EMPLOYER_PAYOR",
                "ROLE_EMPLOYER_ADMIN",
            ];

            if (state.user) {
                return employerRoles.includes(state.user.roleName);
            }
        },
        isLocal: (state) => {
            const localRoles = ["ROLE_LOCAL", "ROLE_LOCAL_ADMIN"];

            if (state.user) {
                return localRoles.includes(state.user.roleName);
            }
        },
        isLocalAdmin: (state) => {
            return state.user && state.user.roleName === "ROLE_LOCAL_ADMIN";
        },
        isSuperAdmin: (state) => {
            return state.user && state.user.roleName === "ROLE_ADMINISTRATOR";
        },
        isParticipant: (state) => {
            return state.user && state.user.roleName === "ROLE_PARTICIPANT";
        },
        userName: (state) => state.user?.name,
        userEmail: (state) => state.user?.email,
        userRole: (state) => state.user?.roleName,
        unreadAlerts: (state) => state.user?.unreadAlerts,
    },

    actions: {
        async getCsrfCookie() {
            try {
                // Get CSRF cookie from Laravel Sanctum
                await axios.get("sanctum/csrf-cookie");
            } catch (error) {
                console.error("Error getting CSRF cookie:", error);
            }
        },

        async authenticateUser(credentials: Credentials) {
            this.getCsrfCookie()
                .then(() => {
                    axios
                        .post("api/auth", credentials)
                        .then((response) => {
                            this.setToken(response.data.token);
                            this.setUser({
                                name: response.data.name,
                                roleName: response.data.role,
                                unreadAlerts: response.data.unreadAlerts,
                                email: response.data.email,
                                participantId: response.data.participantId,
                            });

                            this.getDashboardRoute();

                            // If login is successful, you can redirect the user here
                            let dashboard = "participant.dashboard";

                            if (response.data.role == "ROLE_ADMINISTRATOR") {
                                dashboard = "superadmin.dashboard";
                            }

                            if (this.isEmployer) {
                                dashboard = "employer.dashboard";
                            }

                            if (this.isLocal) {
                                dashboard = "local.dashboard";
                            }

                            router.push({ name: dashboard });
                        })
                        .catch((error) => {
                            // Handle failed login attempt
                            if (
                                error.response &&
                                error.response.status === 401
                            ) {
                                // Here you can handle a 401 Unauthorized response, and return an appropriate message
                                console.error("Invalid credentials");
                                alert("401 Invalid credentials"); // Adjust based on how you want to communicate this to the user
                            } else {
                                alert(error.response.data.message);
                            }
                        });
                })
                .catch((error) => {
                    // Handle error in getting CSRF cookie
                    console.error("Error setting CSRF cookie:", error);
                });
        },

        updateUnreadAlerts(newCount: number) {
            if (this.user) {
                this.user.unreadAlerts = newCount;
            }
        },

        setToken(token: string | null) {
            this.token = token;
            if (token) {
                localStorage.setItem("authToken", token);
            } else {
                localStorage.removeItem("authToken");
            }
        },

        setImpersonateToken(token: string | null, name: string) {
            this.impersonationToken = token;
            this.impersonationName = name;

            if (token) {
                localStorage.setItem("impersonationToken", token);
                localStorage.setItem("impersonationName", name);
                localStorage.setItem("isImpersonating", "1");

                this.isImpersonating = true;
                this.impersonationName = name;
            } else {
                localStorage.removeItem("impersonationToken");
                localStorage.setItem("impersonationName", "name");
                localStorage.setItem("isImpersonating", "0");
                this.isImpersonating = false;
                this.impersonationName = "";
            }
        },

        setUser(newUser: User) {
            this.user = newUser;
            localStorage.setItem("user", JSON.stringify(newUser));
        },

        clearUser() {
            this.user = null;
            localStorage.removeItem("user");
        },

        async startImpersonation(userID: number) {
            try {
                const response = await axios.post(
                    "/api/administrator/impersonate/start",
                    {
                        UserID: userID,
                    }
                );

                this.setImpersonateToken(
                    response.data.token,
                    response.data.name
                );

                this.setUser({
                    name: response.data.name,
                    roleName: response.data.role,
                    unreadAlerts: response.data.unreadAlerts,
                    email: response.data.email,
                    participantId: response.data.participantId,
                });

                this.impersonationToken = response.data.token;
                this.getDashboardRoute();
                this.getStopImpersonationRoute();
            } catch (error) {
                console.log("++ impersonate error ++" + error);
                throw error;
            }
        },

        async stopImpersonation() {
            try {
                const response = await axios.get(
                    "api/administrator/impersonate/stop"
                );

                this.impersonationToken = null;
                this.isImpersonating = false;
                this.impersonationName = "";

                this.setImpersonateToken(
                    null,
                    ''
                );

                this.setUser({
                    name: response.data.name,
                    roleName: response.data.role,
                    unreadAlerts: 0,
                    email: response.data.email,
                    participantId: 0,
                });
            } catch (error) {
                console.log("++ impersonate stop error ++" + error);
                throw error;
            }
        },

        logout() {
            this.setToken(null);
            this.setImpersonateToken(null, "");
            this.$reset();

            useProfileStore().resetUserProfile();
            useParticipantStore().reset();

            router.push({ name: "login" });
        },

        getDashboardRoute() {
            if (this.isSuperAdmin) {
                return this.isImpersonating
                    ? "participant.dashboard"
                    : "superadmin.dashboard";
            } else if (this.isParticipant) {
                return "participant.dashboard";
            } else if (this.isEmployer) {
                return "employer.dashboard";
            } else if (this.isLocal) {
                return "local.dashboard";
            }

            return "participant.dashboard";
        },

        getStopImpersonationRoute() {
            let route = "superadmin.dashboard";

            if (this.isParticipant) {
                route = "superadmin.users.participants";
            } else if (this.isEmployer) {
                route =  "superadmin.users.employers";
            } else if (this.isLocal || this.isLocalAdmin) {
                route =  "superadmin.users.locals";
            }

            this.stopImpersonationRoute = route;
        },
    },
});
