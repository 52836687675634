<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <form>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Type</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Cardholder's Name</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength=""  class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Number</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Expiration Date</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-input">
                                    <label for="" class="body-text">CVV</label>
                                    <input type="text" id="" name="text-group" placeholder="Add CVV" maxlength=""  class="links">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <div class="capp-summary">
                       <div class="capp-summary__option">
                            <p>Selected Coverage</p>
                            <h3>{{ selectedInvoice?.Title || 'No coverage selected' }}</h3>
                       </div>
                       <div class="capp-summary__content">
                            <div class="amount-owed">
                                <p>Amount owed</p>
                                <h3>{{ formatCurrency(selectedInvoice?.AmountOwed) || '$0.00' }}</h3>
                            </div>
                       </div>
                       <div class="capp-summary__action">
                            <a href="#" @click.prevent="openModal('hw-capp-statement', 'Invoices', 'plan-upload plan-card-info plan-c-mrp-claims')">Change Coverage</a>
                       </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <a href="">Back</a>
                    <button>Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useCappInvoicesStore } from '@/stores/cappInvoice';

export default {
    setup() {
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const cappInvoicesStore = useCappInvoicesStore();
        const selectedInvoice = cappInvoicesStore.selectedInvoice;

        return {
            openModal,
            formatCurrency,
            selectedInvoice
        };
    }
};
</script>