import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "optional"
}
const _hoisted_3 = ["placeholder", "rows", "cols", "value", "maxlength", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['textarea', _ctx.customClass])
  }, [
    _createElementVNode("label", {
      for: _ctx.label,
      class: "body-text"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.optional)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (Optional)"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createElementVNode("textarea", {
      placeholder: _ctx.placeholder,
      rows: _ctx.rows,
      cols: _ctx.cols,
      value: _ctx.modelValue,
      maxlength: _ctx.max,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target?.value))),
      readonly: _ctx.readonly,
      class: "links"
    }, null, 40, _hoisted_3)
  ], 2))
}