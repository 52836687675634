import { defineStore } from "pinia";
import { useOptionsFormatter } from "@/utils/optionFormatter";
import { SelectOption, ParticipantData } from "@/interfaces/interfaces";

import axios from "axios";

interface DashboardItem {
    iconClass: string;
    title: string;
    detail: string;
}

export const useParticipantStore = defineStore({
    id: "participant",
    state: () => ({
        alerts: {
            data: [] as SelectOption[],
            fundData: [] as SelectOption[],
            hasUnreadAlerts: false,
        },
        general: null as ParticipantData | null,
    }),

    actions: {
        async fetchAlerts() {
            await axios
                .get("api/participant/alerts")
                .then((response) => {
                    this.alerts.data = useOptionsFormatter(
                        response.data.Alerts
                    );
                    this.alerts.fundData = response.data.UnreadAlertsByFund;
                    this.alerts.hasUnreadAlerts = response.data.HasUnread;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async fetchGeneralData() {
            await axios
                .get("api/participant/general-data")
                .then((response) => {
                    this.general = response.data;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        reset() {
            this.$reset();
        }
    },

    getters: {
        dashboardItems: (state) => {
            if (!state.general) return [];

            return [
                {
                    iconClass: 'iatse-icon-profile-circle',
                    title: 'Participant ID',
                    detail: state.general?.ParticipantID,
                },
                {
                    iconClass: 'iatse-icon-personalcard',
                    title: 'Name',
                    detail: state.general?.Name,
                },
                {
                    iconClass: 'iatse-icon-cake',
                    title: 'Date of Birth',
                    detail: state.general?.DateofBirth,
                },
                {
                    iconClass: 'iatse-icon-security-user',
                    title: 'Age',
                    detail: `${state.general?.Age} Yrs`,
                },
                {
                    iconClass: 'iatse-icon-location',
                    title: 'Address',
                    detail: state.general?.AddressFormatted !== '' ? state.general?.AddressFormatted : '--',
                },
            ];
        },
    }
});
