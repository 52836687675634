import {
    TableData,
    DependentData,
    DependentEntity,
    DependentPerson,
    SelectOption,
    Address,
} from "@/interfaces/interfaces";
import { defineStore } from "pinia";
import { useOptionsFormatter } from "@utils/optionFormatter";
import { useModalStore } from "@/stores/modal";
import axios from "axios";

const initialDependent: DependentPerson = {
    Relationship: null,
    CitizenshipCode: null,
    DateofBirth: null,
    FirstName: null,
    MiddleName: null,
    LastName: null,
    NameSuffix: null,
    MaritalStatus: null,
    MarriageDate: null,
    Salutation: 0,
    Sex: 0,
    SSN: null,
    PersonID: null,
};

export const initialAddress: Address = {
    Street1: "",
    City: "",
    Type: null,
    Country: null,
    State: null,
    Zip: "",
};

export const useDependentStore = defineStore({
    id: "dependent",
    state: () => ({
        Person: { ...initialDependent } as DependentPerson,
        Email: null as string | null,
        PhoneNumber: null as string | null,
        Documents: [],
        ExistingDocuments: [],
        ShowAddress: null as boolean | null,
        Address: null as Address | null,
        ParticipantPrimaryAddress: null as Address | null,
        EditedDependent: null as DependentEntity | null,
        data: [] as DependentData[] | [],
        documentTypes: [] as DocumentType[],
        maritalStatuses: [] as SelectOption[],
        nameSuffixes: [] as SelectOption[],
        salutations: [] as SelectOption[],
        relationships: [] as SelectOption[],
        genders: [] as SelectOption[],
        countries: [] as SelectOption[],
        isEditAction: false,
        currentStep: 1,
        dependents: {
            headers: [
                { title: "Dependent Name" },
                { title: "Relation" },
                { title: "DOB" },
                { title: "Gender" },
                { title: "Coverage Effective Date" },
                { title: "Coverage End Date" },
                { title: "Status" },
                { title: "Date of Marriage" },
                { title: "Documents" },
                { title: "Actions" },
            ],
            rows: [],
        } as TableData,
    }),

    actions: {
        async fetchDependents() {
            await axios
                .get("api/participant/dependents")
                .then((response) => {
                    this.dependents.rows = Object.values(
                        response.data.FormattedDependents
                    );
                    this.data = response.data.Dependents;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async fetchGeneralData() {
            await axios
                .get("api/participant/dependents/add")
                .then((response) => {
                    this.documentTypes = response.data.DocumentTypeDetails;
                    this.maritalStatuses = useOptionsFormatter(
                        response.data.MaritalStatuses
                    );
                    this.nameSuffixes = useOptionsFormatter(
                        response.data.NameSuffixes
                    );
                    this.salutations = useOptionsFormatter(
                        response.data.Salutations
                    );
                    this.genders = useOptionsFormatter(response.data?.Genders);
                    this.relationships = useOptionsFormatter(
                        response.data?.Relationships
                    );
                    this.countries = useOptionsFormatter(
                        response.data?.Countries
                    );

                    if (!this.Person.Relationship) {
                        this.Person.Relationship = this.relationships[0];
                    }

                    if (!this.ParticipantPrimaryAddress) {
                        this.ParticipantPrimaryAddress =
                            response.data.PrimaryAddress;
                    }

                    if (!this.Person.CitizenshipCode) {
                        this.Person.CitizenshipCode = this.countries[1];
                    }
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async deleteDependent(dependent: DependentEntity) {
            try {
                await axios.post("api/participant/delete-dependent", {
                    dependent,
                    _method: "DELETE",
                });

                useModalStore().openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Delete Dependent",
                        content: {
                            type: "text",
                            value: "The Dependent is pending deletion by the admin",
                            icon: "iatse-icon-user-verified",
                        },
                        closeSelf: true,
                    }
                );

                await this.fetchDependents();
                this.clearDependent();
            } catch (error) {
                console.error("There was an error!", error);
            }
        },

        async uploadFiles(data: FormData) {
            try {
                const response = await axios.post(
                    "api/participant/dependents/add-doc",
                    data,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                this.Documents = response.data.Files;
            } catch (err) {
                console.error("There was an error uploading document!", err);
            }
        },

        setEditDependent(dependent: DependentEntity) {
            this.clearDependent();
            let editedDependent = null;

            if (dependent.PartnerID) {
                editedDependent = this.data?.find(
                    (dep: DependentData) => dep.PartnerID == dependent.PartnerID
                );
            } else {
                editedDependent = this.data?.find(
                    (dep: DependentData) =>
                        dep.DependentID == dependent.DependentID
                );
            }

            if (editedDependent) {
                this.Person = {
                    ...editedDependent.Person,
                    DateofBirth: editedDependent.Person?.DateofBirth
                        ? this.formatDateForInput(
                              editedDependent.Person?.DateofBirth
                          )
                        : null,
                };
                this.EditedDependent = {
                    DependentID: editedDependent.DependentID,
                    PartnerID: editedDependent.PartnerID,
                    Type: editedDependent.Type ?? "",
                };

                this.ExistingDocuments = editedDependent.Documents;
                this.Email = editedDependent.Email ?? "";
                this.PhoneNumber = editedDependent.Phone ?? "";
                this.ShowAddress =
                    editedDependent.Address !== null &&
                    editedDependent.Address?.Type?.key != null;
                this.Address = editedDependent.Address
                    ? editedDependent.Address
                    : null;
                this.isEditAction = true;
            }
        },

        formatDateForInput(dateString: string) {
            const datePart = dateString.split(" ")[0]; // Extract "YYYY-MM-DD"
            return datePart; // Return the date part
        },

        clearFormatting(value: string) {
            return value.replace(/\D/g, "");
        },

        clearDependent() {
            this.Person = { ...initialDependent };
            this.Email = null;
            this.isEditAction = false;
            this.PhoneNumber = null;
            this.Address = { ...initialAddress };
            this.ShowAddress = null;
            this.Documents = [];
            this.ExistingDocuments = [];
            this.EditedDependent = null;
        },

        resetDependentStore() {
            this.$reset();
        },
    },
});
