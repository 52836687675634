import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "plan-content gap-20"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_DocumentsSection = _resolveComponent("DocumentsSection")!
  const _component_ReviewDependent = _resolveComponent("ReviewDependent")!
  const _component_AlertComponent = _resolveComponent("AlertComponent")!
  const _component_ReviewUpdateInfo = _resolveComponent("ReviewUpdateInfo")!
  const _component_ReviewInfo = _resolveComponent("ReviewInfo")!

  return ($setup.loading)
    ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.documents && $setup.documents.length)
          ? (_openBlock(), _createBlock(_component_DocumentsSection, {
              key: 0,
              documents: $setup.documents
            }, null, 8, ["documents"]))
          : _createCommentVNode("", true),
        ($setup.formRoute === 'participant.dependents.add')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ReviewDependent, { data: $setup.formData }, null, 8, ["data"])
            ]))
          : ($setup.formRoute === 'participant.personal.info' && $setup.formData?.Person)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_AlertComponent, { errors: $setup.errors }, null, 8, ["errors"]),
                _createVNode(_component_ReviewUpdateInfo, { data: $setup.formData }, null, 8, ["data"])
              ]))
            : _createCommentVNode("", true),
        _createVNode(_component_ReviewInfo, {
          approveButton: $setup.approveButton,
          comments: $setup.formData?.Comments,
          status: $setup.status,
          onHandleReview: $setup.handleReview
        }, null, 8, ["approveButton", "comments", "status", "onHandleReview"])
      ]))
}