<template>
    <div class="container mt-4">
        <Alerts :errors="errors" />
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Contribution History</h1>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    This page allows you to look for specific employer contributions received on behalf of individuals
                    doing covered work in your geographical jurisdiction based on different search parameters. Please
                    note that the broader your search parameter(s) the longer the results will take load.
                </div>
            </div>
            <!-- Filters -->
            <div v-if="(!hasHistory && !changePage) || (loading && !changePage) || reset"
                class="d-flex flex-column gap-4">
                <HistoryFilters :loadingSearch="loading" :reset="reset" @search="searchData" />
            </div>
            <!-- Results -->
            <div v-else-if="(!loading && hasHistory) || changePage" class="row">
                <HistoryResults :loading="loading" :loadingTotals="loadingTotals" :pageChanged="pageChanged"
                    @reset-filters="resetFilters" @fetch-pagination-data="fetchPaginationData" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, computed } from 'vue';
import { usePrint } from '@/composable/usePrint';
import { useNumberFormat } from '@/composable/useNumberFormat';
import { useRoute } from 'vue-router';
import { History, HistoryItem, ContributionFilters, Entity } from '@/interfaces/local';
import { useContributionsStore } from '@/stores/contributions';
import HistoryFilters from '@components/local/contribution-history/HistoryFilters.vue';
import HistoryResults from '@components/local/contribution-history/HistoryResults.vue';
import Alerts from '@components/utils/AlertsComponent.vue';
import axios from 'axios';

export default {
    components: {
        HistoryFilters,
        HistoryResults,
        Alerts
    },

    setup() {
        const loading = ref(false);
        const loadingTotals = ref(false);
        const changePage = ref(false);
        const pageChanged = ref(false);
        const reset = ref(false);
        const page = ref(1);
        const errors: Ref<string[]> = ref([]);
        const contributionsStore = useContributionsStore();
        const hasTotals = computed(() => (contributionsStore.fetchTotals !== null));
        const hasHistory = computed(() => (contributionsStore.fetchContributions.length !== 0));

        const resetFilters = () => {
            contributionsStore.resetData();
            changePage.value = false;
            reset.value = true;
            page.value = 1;
        }

        const searchData = async (payload: ContributionFilters, existingFilters: boolean | null) => {
            loading.value = true;
            contributionsStore.setContributions([]);
            let filters = JSON.parse(JSON.stringify(payload));
            let formData = {
                ...filters,
                Page: page.value,
            };

            if (!existingFilters) {
                const hasEmployers = filters?.Employers?.some((employer: Entity) => employer.ID !== 0);
                const hasProductions = filters?.Productions?.some((prod: Entity) => prod.ID !== 0);
                const hasFunds = filters?.Funds?.some((fund: Entity) => fund.ID !== 0);
                const hasPayors = filters?.Payors?.some((payor: Entity) => payor.ID !== 0);

                formData = {
                    ...filters,
                    Employers: !hasEmployers ? null : filters.Employers.map((item: Entity) => item.ID),
                    Productions: !hasProductions ? null : filters.Productions.map((item: Entity) => item.ID),
                    Payors: !hasPayors ? null : filters.Payors.map((item: Entity) => item.ID),
                    Funds: !hasFunds ? null : filters.Funds.map((item: Entity) => item.ID),
                    Page: page.value,
                };

                contributionsStore.setFilters(formData);
            }

            if (changePage.value) {
                pageChanged.value = false;
            }

            await axios
                .post('api/local/contribution-history', { ...formData })
                .then(response => {
                    contributionsStore.setContributions(
                        response.data.Records
                    );

                    contributionsStore.setPaginator(response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(error.response).flat() as string[];
                    }
                })
                .finally(() => {
                    loading.value = false;
                    reset.value = false;

                    if (changePage.value) {
                        pageChanged.value = true;
                    }

                    setTimeout(() => errors.value = [], 3500)
                })

            if (!hasTotals.value) {
                await getTotals();
            }
        };

        const getTotals = async () => {
            loadingTotals.value = true;
            const data = contributionsStore.getFilters;

            await axios
                .post('api/local/contribution-history-totals', { ...data })
                .then(response => {
                    contributionsStore.setTotals(response.data);
                })
                .catch(error => {
                    console.error('There have been an error.')
                })
                .finally(() => {
                    loadingTotals.value = false;
                })
        }

        const fetchPaginationData = async (pageNumber: number) => {
            page.value = pageNumber;
            changePage.value = true;

            await searchData(contributionsStore.getFilters, true);
        }

        return {
            loading,
            loadingTotals,
            hasHistory,
            pageChanged,
            changePage,
            errors,
            reset,
            contributionsStore,
            resetFilters,
            fetchPaginationData,
            searchData,
        }
    }
}
</script>