<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper">
            <div class="card-header">
                <h5>Transaction History</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>
        </div>

        <CobraPayments :cobraPayments="cobraStatements" :title="TransactionsHistory"/>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import CobraPayments from '@/components/participant/health-welfare/CobraPayments.vue';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { PlanCobraSummary, CobraPayment } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const { formatCurrency } = useCurrencyFormatter();

        const cobraStatements = ref<CobraPayment[]> ([]);

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = () => {
            const params: Record<string, any> = {};

            axios
                .get('/api/participant/health-welfare/cobra-payments-history', { params })
                .then((response) => {
                    // Store other plan summary details
                    cobraStatements.value = response.data.CobraInvoices;
                    dataLoaded.value = true;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };


        onMounted(() => {
            fetchData(); // Initial fetch on mount
        });

        return {
            cobraStatements,
            dataLoaded,
            formatCurrency
        };
    },

    components: {
        CobraPayments
    }
};
</script>