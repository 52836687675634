import {
    ModalProps,
    AssignmentByFund,
    EditedAssignmentByFund,
    Assignment,
    SlidersByFund,
    SliderConfig,
    SelectOption,
} from "@/interfaces/interfaces";
import {
    Beneficiary,
    NewBeneficiary,
    NewBeneficiaryCard,
    BeneficiaryDependent,
} from "@/interfaces/beneficiary";
import { defineStore } from "pinia";
import { useModalStore } from "@/stores/modal";
import { useDateFormatter } from "@utils/dateFormatter";
import axios, { AxiosError } from "axios";

const initialNewBene: NewBeneficiary = {
    BeneficiaryID: null,
    Type: null,
    Relationship: null,
    FirstName: null,
    LastName: null,
    Suffix: null,
    DateofBirth: null,
    Address: {
        Street1: "",
        City: "",
        Country: { key: "US", value: "United States" },
        State: null,
        Zip: "",
    },
    Documents: [],
};

export const useBeneficiaryStore = defineStore({
    id: "beneficiary",

    state: () => ({
        beneficiary: null as Beneficiary | null,
        deletedBeneficiary: null as Beneficiary | number | null,
        beneficiaries: [] as Beneficiary[],
        dependents: [],
        nonAllocatedBenis: [] as Beneficiary[],
        newBeneficiary: {
            ...JSON.parse(JSON.stringify(initialNewBene)),
        } as NewBeneficiary,
        newBeneficiaries: [] as NewBeneficiary[],
        newBeneficiariesCard: [] as NewBeneficiaryCard[],
        beneCards: [] as NewBeneficiaryCard[],
        beneSliders: null as SlidersByFund | null,
        assignments: null as AssignmentByFund | null,
        editedAssignments: null as EditedAssignmentByFund | null,
        isAddAction: false,
        isDeleteAction: false,
        isEditAction: false,
        continueAssignment: false,
        addFromDependents: false,
        dependentEdit: false,
        eligibleFunds: [] as string[],
        documentTypes: [] as SelectOption[],
        allocationOption: "Custom" as string,
        deleteAllocationOption: "Equally" as string,
        allocationOptions: [
            {
                label: "Equally",
                value: "Redistribute allocation equally among beneficiaries",
            },
            {
                label: "Custom",
                value: "Distribute custom allocation to the beneficiaries",
            },
        ],
    }),

    actions: {
        async fetchBeneficiaries() {
            await axios
                .get("api/participant/beneficiaries")
                .then((response) => {
                    this.beneficiaries = Object.values(
                        response.data.Beneficiaries
                    );

                    this.dependents = response.data.Dependents;
                    this.documentTypes = response.data.DocumentTypes;
                    this.getBeneCards();
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async handleEditBene(bene: Beneficiary | NewBeneficiary) {
            try {
                await axios.post("api/participant/beneficiaries/edit", {
                    bene,
                    _method: "PATCH",
                });

                await this.fetchBeneficiaries();
                useModalStore().closeLastModal();
                this.isEditAction = false;
                this.resetNewBene();
            } catch (error) {
                console.error("There was an error!", error);
            }
        },

        async handleSubmitNewBenes(deleteLast: boolean) {
            try {
                await axios.post("api/participant/beneficiaries/save", {
                    NewBeneficiaries: this.newBeneficiaries,
                    DeletedBeneficiary: {
                        BeneficiaryID: this.deletedBeneficiary,
                        Type: this.beneficiaries.find(
                            (ben) =>
                                ben.BeneficiaryID == this.deletedBeneficiary
                        )?.Type,
                    },
                    Assignments: this.editedAssignments,
                    IsAddAction: this.isAddAction,
                    IsEditAction: this.isEditAction,
                    IsDeleteAction: this.isDeleteAction,
                    DeleteLastBene: deleteLast,
                    _method: "POST",
                });

                this.newBeneficiaries = [];
                this.newBeneficiariesCard = [];
                this.isAddAction = false;
                this.isDeleteAction = false;
                this.isEditAction = false;
                this.resetNewBene();
            } catch (error) {
                console.error("There was an error!", error);

                if (error instanceof AxiosError) {
                    if (error.response) {
                        return Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                }
            }
        },

        async uploadFiles(data: FormData, beneId?: string | number) {
            try {
                const response = await axios.post(
                    "api/smartsearch/beneficiary-file",
                    data,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (!beneId) {
                    this.newBeneficiary.Documents = response.data.Files;
                } else {
                    const bene = this.beneficiaries?.find(
                        (bene) => bene.BeneficiaryID == beneId
                    );

                    if (bene) {
                        bene.Documents = response.data.Files;
                    }
                }
            } catch (err) {
                console.error("There was an error uploading document!", err);
            }
        },

        async deleteAllocation(id?: string | number, entityType?: string) {
            try {
                const response = await axios.post(
                    "api/participant/beneficiaries/delete-allocation",
                    {
                        Allocation: id,
                        Type: entityType,
                        _method: "PATCH",
                    }
                );

                await this.fetchBeneficiaries();
                this.getSliders();
                this.getBeneCards();
                this.initAssignments();
            } catch (err) {
                console.error("There was an error deleting allocation!", err);
            }
        },

        getEditBene(bene: Beneficiary) {
            this.isEditAction = true;
            this.newBeneficiary = {
                BeneficiaryID: bene.BeneficiaryID,
                Relationship: bene.Additional.RelationOption,
                FirstName: bene.Additional.FirstName,
                LastName: bene.Additional.LastName,
                Suffix: bene.Additional.Suffix,
                DateofBirth: bene.General.DOB,
                Address: bene.Additional.Address ?? null,
                Documents: bene.Documents,
                Type: bene.Type,
            };
        },

        editDependent(dependentId: string | number) {
            this.addFromDependents = true;

            this.newBeneficiary = this.newBeneficiaries.find(
                (ben) => ben.DependentID === dependentId
            ) ??
                this.dependents.find(
                    (dep: BeneficiaryDependent) =>
                        dep.DependentID == dependentId ||
                        dep.PartnerID == dependentId
                ) ?? { ...initialNewBene };
        },

        getAddress(newBene: NewBeneficiary | BeneficiaryDependent) {
            if (!newBene.Address) {
                return "";
            }

            const { Street1, City, Country, State, Zip } = newBene.Address;

            const countryValue =
                Country?.key && Country.key != 0 ? Country.key : "";
            const stateValue = State?.key && State.key != 0 ? State.key : "";

            // Join the address parts, filtering out empty values
            return [Street1, City, stateValue, countryValue, Zip]
                .filter((part) => String(part).trim() !== "") // Convert to string first
                .join(", ");
        },

        setEligibleFunds(data: string[]) {
            this.eligibleFunds = data;
        },

        filterAssignments() {
            if (this.assignments) {
                Object.entries(this.assignments).forEach(
                    ([fund, assignment]: [string, Assignment | null]) => {
                        if (this.editedAssignments && assignment?.IsEdited) {
                            this.editedAssignments[fund] = assignment;
                        }
                    }
                );
            }
        },

        initAssignments() {
            const initialAssignments = this.eligibleFunds.reduce(
                (result: AssignmentByFund, fund: string) => {
                    result[fund] = {
                        Allocations: {},
                        IsEdited: this.isDeleteAction ? true : false,
                    };

                    // Add allocations based on the sliders generated for this fund
                    if (this.beneSliders) {
                        this.beneSliders[fund].forEach((slider) => {
                            result[fund].Allocations = {
                                ...result[fund]?.Allocations,
                                [slider.entityId]: {
                                    Percentage: slider.value,
                                    GroupLevel: slider.level,
                                    Type: slider.entityType,
                                },
                            };
                        });
                    }

                    return result;
                },
                {}
            );

            this.assignments = initialAssignments;

            this.editedAssignments = this.eligibleFunds.reduce(
                (result: EditedAssignmentByFund, fund: string) => {
                    result[fund] = null;

                    return result;
                },
                {} as EditedAssignmentByFund
            );
        },

        getSliders() {
            const slidersByFund: SlidersByFund = this.eligibleFunds.reduce(
                (result: SlidersByFund, fund: string) => {
                    // Create sliders for existing beneficiaries
                    let redistributedValue = 0;

                    if (this.isDeleteAction) {
                        const beneToBeDeleted = this.beneficiaries.find(
                            (bene) =>
                                Number(bene.BeneficiaryID) ===
                                Number(this.deletedBeneficiary)
                        );

                        // Get current allocations for this fund
                        const deletedAllocationRow =
                            beneToBeDeleted?.Allocations?.rows.find(
                                (row) => row.Funds === fund
                            );
                        const deletedAllocationPercentage = deletedAllocationRow
                            ? Number(deletedAllocationRow.Percentage)
                            : 0;

                        if (this.allocationOption === "Equally") {
                            const remainingBeneficiaries =
                                this.beneficiaries.filter(
                                    (bene) =>
                                        bene.BeneficiaryID !=
                                        this.deletedBeneficiary
                                );

                            // Calculate the total remaining percentage to redistribute equally
                            redistributedValue =
                                remainingBeneficiaries.length > 0
                                    ? deletedAllocationPercentage /
                                      remainingBeneficiaries.length
                                    : 0;
                        } else {
                            redistributedValue = deletedAllocationPercentage;
                        }
                    }

                    const existingBeneficiarySliders: SliderConfig[] =
                        this.beneficiaries.map((bene) => {
                            const isNotDeletedBene =
                                Number(bene.BeneficiaryID) !==
                                Number(this.deletedBeneficiary);

                            const allocationRow = bene.Allocations?.rows.find(
                                (row) => row.Funds === fund
                            );

                            const defaultLevel = allocationRow
                                ? allocationRow.Level
                                : "Primary";

                            let defaultValue = 0;

                            // Default allocations for sliders
                            if (this.allocationOption === "Equally") {
                                defaultValue =
                                    100 /
                                    (this.beneficiaries.length +
                                        this.newBeneficiaries.length);
                            } else {
                                defaultValue =
                                    allocationRow &&
                                    allocationRow?.Status != "Pending Delete"
                                        ? Number(allocationRow.Percentage)
                                        : 0;

                                console.log(
                                    allocationRow?.Status,
                                    defaultValue,
                                    "@row"
                                );
                            }

                            // Calculate DELETE Allocations - Redistribute to other beneficiaries
                            if (this.deleteAllocationOption === "Equally") {
                                if (isNotDeletedBene) {
                                    defaultValue =
                                        Number(
                                            allocationRow &&
                                                allocationRow?.Status !=
                                                    "Pending Delete"
                                                ? allocationRow.Percentage
                                                : 0
                                        ) + redistributedValue;
                                } else {
                                    defaultValue = 0; // For the beneficiary being deleted, set value to 0 and mark as non-editable
                                }
                            } else if (
                                this.deleteAllocationOption === "Custom"
                            ) {
                                if (!isNotDeletedBene) {
                                    defaultValue = 0;
                                }
                            }

                            return {
                                label: `${bene.Name} (${bene.General.Relation})`,
                                min: 0,
                                max: 100,
                                step: 5,
                                level: defaultLevel,
                                value: defaultValue > 100 ? 100 : defaultValue,
                                id: `${bene.BeneficiaryID} - ${fund}`,
                                entityId: bene.BeneficiaryID || 0,
                                entityType: bene.Type,
                                editable: isNotDeletedBene,
                            };
                        });

                    // Create sliders for new beneficiaries
                    if (!this.isDeleteAction) {
                        const newBeneficiarySliders: SliderConfig[] =
                            this.newBeneficiariesCard.map((bene, idx) => {
                                const defaultValue =
                                    this.allocationOption === "Equally"
                                        ? 100 /
                                          (this.beneficiaries.length +
                                              this.newBeneficiaries.length)
                                        : 0;

                                return {
                                    label: `${bene.Name} (${bene.Relation})`,
                                    min: 0,
                                    max: 100,
                                    step: 5,
                                    level: "Primary",
                                    value: defaultValue,
                                    id: `new-${idx} - ${fund}`,
                                    entityId: `new-${idx}`,
                                    entityType: "UIAdminBeneficiary",
                                    editable: true,
                                };
                            });

                        // Combine both existing and new beneficiaries' sliders for the fund
                        result[fund] = [
                            ...existingBeneficiarySliders,
                            ...newBeneficiarySliders,
                        ];
                    } else {
                        result[fund] = [...existingBeneficiarySliders];
                    }

                    return result;
                },
                {}
            );

            this.beneSliders = slidersByFund;
        },

        getBeneCards() {
            if (this.isAddAction) {
                this.beneCards = this.newBeneficiariesCard;
            } else {
                this.beneCards = this.beneficiaries.map((bene) => {
                    return {
                        Name: bene.Name,
                        Address: bene.Additional.AddressFormatted,
                        DOB: bene.General.DOB,
                        Relation: bene.General.Relation ?? "",
                    };
                });
            }
        },

        getDependentsCards(
            includeAddress: boolean,
            selectedIds: string[] = []
        ) {
            // Filter dependents based on the selectedIds array if provided
            const filteredDependents =
                selectedIds.length > 0
                    ? this.dependents.filter(
                          (dep: BeneficiaryDependent) =>
                              selectedIds.includes(String(dep.DependentID)) ||
                              selectedIds.includes(String(dep.PartnerID))
                      )
                    : this.dependents;

            if (!filteredDependents.length) {
                return;
            }

            return filteredDependents.reduce(
                (acc, dep: BeneficiaryDependent) => {
                    const {
                        DependentID,
                        PartnerID,
                        FirstName,
                        SSN,
                        LastName,
                        Suffix,
                        DateofBirth,
                        Relationship,
                        Address,
                    } = dep;

                    // Create the base card
                    const baseCard = {
                        Name: dep.Name,
                        DOB: useDateFormatter(dep.DateofBirth),
                        Relation: dep.Relationship.value,
                    };

                    const key = dep.PartnerID ?? dep.DependentID ?? "unknown";

                    // Add Address if includeAddress is true
                    if (includeAddress) {
                        const cardWithAddress = {
                            ...baseCard,
                            Address: dep.AddressFormatted ?? "N/A",
                        };

                        if (key) {
                            acc[key] = cardWithAddress;
                        }

                        this.newBeneficiariesCard.push(cardWithAddress);
                        this.newBeneficiaries.push({
                            SSN: SSN,
                            FirstName: FirstName,
                            LastName: LastName,
                            Suffix: Suffix || null,
                            DateofBirth: DateofBirth || null,
                            Address: Address || null,
                            Relationship: Relationship || null,
                            DependentID: DependentID ?? PartnerID,
                        });
                    } else if (key) {
                        acc[key] = baseCard;
                    }

                    return acc;
                },
                {} as Record<string, NewBeneficiaryCard>
            );
        },

        getSlidersByFund(fund: string): SliderConfig[] {
            if (this.beneSliders && this.beneSliders[fund]) {
                return this.beneSliders[fund];
            }

            return [];
        },

        reloadAssignments() {
            this.getSliders();
            this.getBeneCards();
            this.initAssignments();
        },

        reset() {
            this.$reset();
        },

        resetNewBene() {
            this.newBeneficiary = {
                ...JSON.parse(JSON.stringify(initialNewBene)),
            };
        },
    },

    getters: {
        beneficiaryNames: (state) => {
            const names = (
                state.isAddAction ? state.newBeneficiariesCard : state.beneCards
            ).map((bene) => bene.Name);
            const lastName = names.pop(); // Remove the last name

            let joinedNames = "";

            if (names.length > 0) {
                joinedNames = names.join(", ") + " and " + lastName;
            } else if (lastName) {
                joinedNames = lastName; // If there's only one name, return it
            }

            const hasEditedAssignments = state.editedAssignments
                ? Object.values(state.editedAssignments).some(
                      (value) => value !== null
                  )
                : false;

            if (hasEditedAssignments) {
                return (
                    joinedNames +
                    " has been added as a beneficiary and funds has been assigned."
                );
            }

            return joinedNames + " has been added as beneficiary";
        },

        getModalProps(): ModalProps {
            return {
                title: "Distribute allocations",
                content: {
                    type: "option",
                    value: this.allocationOptions,
                    selected: this.allocationOption,
                    name: "Beneficiary",
                    icon: "iatse-icon-user-verified",
                },
                onConfirm: (payload?: string) => {
                    if (payload) {
                        this.allocationOption = payload;
                    }

                    useBeneficiaryStore().reloadAssignments();
                    useModalStore().openModal(
                        "assign-fund",
                        "Assign Fund To Beneficiary",
                        "plan-upload"
                    );
                },
            };
        },

        getDeleteModalProps(): ModalProps {
            return {
                title: "Distribute allocations",
                content: {
                    type: "option",
                    value: this.allocationOptions,
                    selected: this.deleteAllocationOption,
                    name: "Beneficiary",
                    icon: "iatse-icon-user-verified",
                },
                onConfirm: (payload?: string) => {
                    if (payload) {
                        this.deleteAllocationOption = payload;
                    }

                    useBeneficiaryStore().reloadAssignments();
                    useModalStore().openModal(
                        "assign-fund",
                        "Assign Fund To Beneficiary",
                        "plan-upload"
                    );
                },
            };
        },

        getFundRequirementsModal(): ModalProps {
            return {
                title: "Annuity and Pension Primary Beneficiary Requirements",
                content: {
                    type: "text",
                    value: '<p class="mb-20"> If you are married, your primary beneficiary must be your spouse unless you obtain a waiver. You can download a copy by <a href=""> clicking on this link </a>. Please complete the form and obtain all signatures and notarizations before proceeding. You can upload a scanned copy with this form once it is read. If you have already obtained this form, you may upload it with your submission on the next screen. </p> <p>If you are not married, you may proceed without this form. Your submission will be reviewed and if its subject to this requirement, will be accepted only if the necessary form accompanies your submission. </p>',
                    icon: "",
                },
                confirmButtons: ["Go Back", "Continue"],
            };
        },

        getSuccessDeleteModalProps: (state): ModalProps => {
            const deletedBeneficiary = state.beneficiaries.find(
                (bene) => bene.BeneficiaryID == Number(state.deletedBeneficiary)
            );

            let message = "Beneficiary was successfully removed";

            if (deletedBeneficiary?.Additional.PendingDeletion) {
                message = `Delete Request for ${deletedBeneficiary?.Name} already exists`;
            } else if (!deletedBeneficiary?.Additional.PendingBene) {
                message = "Delete Request was successfully created";
            }

            return {
                title: "Beneficiary Remove Successfully",
                content: {
                    type: "text",
                    value: message,
                    icon: "iatse-icon-user-verified",
                },
                onConfirmButton: "Ok",
                onConfirm: () => {
                    useModalStore().closeLastModal();
                },
            };
        },
    },
});
