<template>
    <FundPanel cardId="card-hw" :alerts="alerts">
        <template #icon>
            <i class="iatse-icon-fund-hw"></i>
        </template>
        <template #title>
            Health &amp; Welfare
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__hw">
                    <div class="fund-cover-header">
                        <h4>Current Coverage</h4>
                    </div>
                    <div class="fund-coverages" v-if="hasPlanCCurrentInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">{{ data.PlanCInvoices.Current.Paid ? 'You selection will be processed soon' : 'Not Currently Enrolled' }}</h3>
                            </div>
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions" v-if="!data.PlanCInvoices.Current.Paid">
                            <a href="#" 
                                class="btn btn-primary plan-modal"
                                @click.prevent="openModal('hw-capp-statement', 'Capp', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Select/Pay Coverage
                            </a>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages"  v-else-if="hasPlanCobraCurrentInvoice">
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">Not Currently Enrolled</h3>
                            </div>
                            <div class="coverage-column-items">
                                <div class="coverage-column">
                                    <i class="iatse-icon-calendar"></i>
                                    <p class="links">Coverage Period: TBD </p>
                                </div>
                            </div>
                        </div>
                        <div class="coverage-column__actions">
                            <a href="#" class="btn btn-primary plan-modal"
                                @click.prevent="openModal('hw-cobra-statements', 'Cobra Statements', 'plan-upload plan-card-info plan-c-mrp-claims')">
                                Make Payment
                            </a>
                        </div>
                    </div> <!-- #end -->
                    <div class="fund-coverages"  v-else>
                        <div class="coverage-row">
                            <div class="coverage-column">
                                <h3 class="plan-modal">Not Currently Enrolled</h3>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
         </template>
         <template #related-links>
             <a href="" class="btn btn-tertiary btn-large plan-modal" v-if="data.IsAvailableForClaim"
                @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">View/Submit
             Claims</a>
             <a href="" class="btn btn-tertiary btn-large plan-modal"
                 @click.prevent="openModal('dependents', 'My Dependents', null)"> Dependents
             </a>
             <a href="" @click.prevent class="btn btn-tertiary btn-large plan-modal">ID Card(s)</a>
             <a href="https://octobercms.dev.digitalgarage.ro/en/resources" class="btn btn-tertiary btn-large"
                 target="_blank">Funds Resources</a>
         </template>
     </FundPanel>
 </template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { HealthDashboardInfo } from '@/interfaces/health';
import { useModalStore } from '@/stores/modal';
import FundPanel from '@/components/cards/FundPanel.vue';

export default defineComponent({
    props: {
        alerts: Array,
        data: {
            type: Object as () => HealthDashboardInfo,
            required: true
        },
        hasPlanCUpcomingInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCCurrentInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCobraUpcomingInvoice: {
            type: Boolean,
            required: true
        },
        hasPlanCobraCurrentInvoice: {
            type: Boolean,
            required: true
        }
    },

    setup(props) {
        const { data } = toRefs(props);

        const openModal = useModalStore().openModal;


        return {
            openModal
        };
    },

    components: {
        FundPanel
    }
});
</script>