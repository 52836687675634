<template>
    <Alerts :success="success" :errors="errors" />
    <div v-if="loading">Loading...</div>
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="missing-contribution-form" class="form missing-contribution" method="post" @submit.prevent>
            <div class="inputs-wrapper">
                <div class="custom-select links">
                    <label class="body-text"> Employer/Production </label>
                    <Multiselect v-model="filters.Employers" :options="employers" placeholder="Type to search"
                        :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                        @select="handleEmployerChange" label="OrgName" track-by="OrganizationID"
                        @search-change="handleEmployersSearch" :loading="loadingEmployers">
                    </Multiselect>
                </div>
                <TextInput type="text" label="Member Name or SSN" placeholder="Enter Member Name or SSN" :max=12
                    v-model:modelValue="filters.MemberNameSSN" />
                <DateInput label="Work Period*" v-model:modelValue="filters.WorkPeriod.StartDate" />
                <DateInput label="" v-model:modelValue="filters.WorkPeriod.EndDate" />
                <TextInput type="text" label="Work Location" placeholder="Enter Work Location" :max=50
                    v-model:modelValue="filters.WorkLocation" />
                <SelectComponent label="State\Province" v-model:selectedOption="filters.State" :options="filteredStates"
                    :single-arrow="true" />
            </div>

            <div class="action-buttons pt-5">
                <button type="button" @click.prevent="closeModal" class="btn btn-secondary" :disabled="loadingSubmit">
                    Discard
                </button>
                <button type="submit" class="btn btn-primary" :disabled="loadingSubmit" @click.prevent="handleSubmit">
                    {{ loadingSubmit ? 'Submitting...' : 'Submit' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, onMounted, computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';
import { CountryStates, SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate: string,
        EndDate: string,
    };
    Employers: Employer[] | null;
    ParticipantID: string;
    MemberNameSSN: string;
    WorkLocation: string;
    State: SelectOption;
}

interface Employer {
    OrganizationID: string | number;
    OrgName: string;
}

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        SelectComponent,
        Multiselect
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const modalStore = useModalStore();
        const profileStore = useProfileStore();
        const errors = ref<string[]>([]);
        const success = ref(null);
        const info = ref('');
        const loading = ref(true);
        const loadingEmployers = ref(false);
        const loadingProductions = ref(false);
        const loadingSubmit = ref(false);
        const allOption = { OrganizationID: 0, OrgName: 'ALL' };
        const initialFilters = {
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            ParticipantID: '',
            MemberNameSSN: '',
            WorkLocation: '',
            State: { key: 0, value: 'Select State' },
        }
        const filters: Filters = reactive(JSON.parse(JSON.stringify(initialFilters)));
        const employers = ref<Employer[]>([]);
        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.OrganizationID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            loadingEmployers.value = true;

            if (query.length >= 3) {
                try {
                    const [employersResponse] = await Promise.all([
                        axios.get(`/api/organizations-types?query=${query}`),
                        // axios.get(`/api/participant/productions?searchTerm=${query}`)
                    ]);

                    // Combine the results
                    employers.value = [
                        ...employersResponse.data,
                        // ...productionsResponse.data
                    ];
                }
                catch (error) {
                    console.error('Error searching employer:', error);
                }
            }
            loadingEmployers.value = false;
        };

        // Fetch Data
        const fetchData = async () => {
            try {
                if (!profileStore.countries || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = 'US';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter(state => state.CountryKey === selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        const closeModal = () => {
            emit('close');
        }

        const handleEmployerChange = (newSelection: Employer) => {
            if (newSelection.OrganizationID === 0) {
                filters.Employers = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters.Employers)).filter((type: Employer) => type.OrganizationID !== 0)
                filters.Employers = [...filteredTypes];
            }
        }

        const handleSubmit = async () => {
            loadingSubmit.value = true;
            const formData = { ...filters, State: filters?.State?.value };

            if (!hasEmployers.value) {
                formData.Employers = null;
            }

            await axios
                .post('api/participant/missing-contributions', {
                    ...formData
                })
                .then(response => {
                    success.value = response.data.success;
                    // Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })

            loadingSubmit.value = false;
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSubmit,
            loadingEmployers,
            loadingProductions,
            errors,
            success,
            filters,
            employers,
            hasEmployers,
            filteredStates,
            handleEmployerChange,
            handleEmployersSearch,
            handleSubmit,
            closeModal
        };
    }
});
</script>
