<template>
    <div class="aside-contact">
        <img src="../../assets/images/contact.svg" alt="contact image" />
        <div class="aside-contact-text text-300 opacity-80">
            Having Trouble with the Portal. <br />
            Feel free to reach out to us.
        </div>
        <a href="https://octobercms.dev.digitalgarage.ro/en/contact" target="_blank"
            class="btn btn-secondary btn-contact">Contact Us</a>
    </div>
</template>

<script lang="ts">
export default {
    name: 'ContactComponent'
}
</script>