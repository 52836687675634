<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <form>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Postal/Zip Code</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Type</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Cardholder's First Name</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Cardholder's Last Name</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Number</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Expiration Date</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-input">
                                    <label for="" class="body-text">&emsp;</label>
                                    <input type="email" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">CVV</label>
                                    <input type="email" id="" name="text-group" placeholder="Add CVV" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <div class="cobra-summary">
                        <table class="primary-table">
                            <thead>
                                <tr>
                                    <th>Invoice</th>
                                    <th>Coverage Period</th>
                                    <th>Amount Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(invoice, index) in selectedInvoicesDetails" :key="index">
                                    <td>{{ invoice.PlanName }}</td>
                                    <td>{{ invoice.InsuranceStartDate + ' - ' + invoice.InsuranceEndDate }}</td>
                                    <td>{{ formatCurrency(invoice.Amount) }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between align-items-center" v-if="payInAdvanceSelected">
                            <p>Additional Amount</p>
                            <h4>{{ payInAdvance !== null ? formatCurrency(payInAdvance) : 0 }}</h4>
                        </div>

                        <!-- Additional Amount -->
                        <div class="d-flex justify-content-between align-items-center">
                            <p>Total Amount</p>
                            <h4>{{ formatCurrency(total) }}</h4>
                        </div>

                        <a href="#" class="btn btn-primary">Edit COBRA Payment</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useInvoicesStore } from '@/stores/cobraInvoices';

export default {
    setup() {
        const invoicesStore = useInvoicesStore();
        const { formatCurrency } = useCurrencyFormatter();
        const { selectedInvoicesDetails, total, payInAdvanceSelected, payInAdvance } = invoicesStore;

        return {
            selectedInvoicesDetails,
            total,
            formatCurrency,
            payInAdvanceSelected,
            payInAdvance
        };
    }
};
</script>