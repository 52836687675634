import { defineStore } from "pinia";
import { GrandTotal, History, ContributionFilters } from "@/interfaces/local";
import { Paginator } from "@/interfaces/admin";

export const useContributionsStore = defineStore({
    id: "totals",

    state: (): {
        totals: GrandTotal | null;
        filters: ContributionFilters;
        contributions: History[] | [];
        paginator: Paginator;
    } => ({
        totals: null,
        filters: {
            StartDate: '',
            EndDate: '',
            Employers: null,
            Productions: null,
            Funds: null,
            Payors: null,
        },
        contributions: [],
        paginator: { per_page: 0, total: 0, last_page: 0, current_page: 0 },
    }),

    getters: {
        fetchContributions(): History[] {
            return this.contributions;
        },

        fetchTotals(): GrandTotal | null {
            return this.totals;
        },

        getPaginator(): Paginator {
            return this.paginator;
        },

        getFilters(): ContributionFilters {
            return this.filters;
        },

        getTotalRows(): number | null {
            return this.paginator.total;
        },
    },

    actions: {
        setContributions(history: History[] | []) {
            this.contributions = history;
        },

        setTotals(grandTotals: GrandTotal | null) {
            this.totals = grandTotals;
        },

        setPaginator(pag: Paginator) {
            this.paginator = pag;
        },

        setFilters(filterData: ContributionFilters) {
            this.filters = filterData;
        },

        toggleDetails(index: number) {
            this.contributions.forEach((entry: History, entryIndex: number) => {
                if (entryIndex !== index) {
                    entry.showDetails = false;
                }
            });

            this.contributions[index].showDetails =
                !this.contributions[index].showDetails;
        },

        resetData() {
            this.contributions = [];
            this.totals = null;
            this.filters = {
                StartDate: '',
                EndDate: '',
                Employers: null,
                Productions: null,
                Funds: null,
                Payors: null,
            };
            this.paginator = {
                per_page: 0,
                total: 0,
                last_page: 0,
                current_page: 0,
            };
        },
    },
});
