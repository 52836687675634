<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">Employer Contributions in Your Jurisdiction</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 mb-4 w-100">
                        <h6 class="card-title"> Employer Contributions / Payments </h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <div class="d-flex flex-column gap-2 align-items-start w-100 mb-4">
                                <span class="fw-bold">Most Recent</span>
                                <div v-for="(contribution) in contributions" :key="contribution.EmployerAliasID"
                                    class="w-100">
                                    <!-- Contribution item -->
                                    <div
                                        class="contribution-item d-flex gap-2 align-items-center justify-content-between w-100">
                                        <router-link :to="{
                                            name: 'local.recent-contribution', params: {
                                                    Employers: contribution.EmployerAliasID,
                                                    StartDate: contribution.WorkStart,
                                                    EndDate: contribution.WorkEnd
                                                }
                                            }" class="contribution-link w-100"
                                        >
                                            {{ contribution.Employer }}
                                        </router-link>
                                        <span class="contribution-date w-100">{{ contribution.ReceiptDate }}</span>
                                    </div>
                                    <!-- Divider -->
                                    <hr class="mt-2 mb-2">
                                </div>
                            </div>
                            <router-link :to="{ name: 'local.contribution-history' }">
                                Contributions History Search </router-link>
                            <router-link :to="{ name: 'local.missing-contributions' }">
                                Missing Contributions Inquiry
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-flex flex-column gap-4 mb-4 w-100">
                        <h6 class="card-title">Contract Info</h6>
                        <router-link :to="{ name: 'local.contract-information' }">
                            Summarized Contract Information
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ContributionPreview } from '@/interfaces/local';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        contributions: {
            type: Object as PropType<ContributionPreview | null>,
            required: false
        }
    }
})
</script>

<style scoped>
a {
    text-decoration: none;
}

.card-title {
    text-decoration: none;
    color: #0d6efd;
}

.card-title:hover {
    cursor: pointer;
}
</style>