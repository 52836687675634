<template>
    <div :class="['plan-general-details', classes]">
        <template v-if="title && type === 'pension'">
            <h4>{{ title }}</h4>
        </template>
        <div v-else-if="title" class="plan-general-header">
            <h4>{{ title }}
                <i v-if="info" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip fs-lg">
                    <span class="tooltip-text">{{ info }}</span>
                </i>
            </h4>
            <p class="plan-modal" @click="openModal">
                <span class="text-underline plan-modal"> {{ actionButton }} </span>
                <i class="iatse-icon-arrow-down plan-modal"> </i>
            </p>
        </div>
        <template v-if="multiple">
            <div v-for="(parentItem, parentIdx) in items" class="plan-general-container" :key="parentIdx">
                <!-- for needed green items -->
                <template v-if="parentIdx === 1 && greenItems?.length">
                    <div class="plan-general-detail-container__alert plan-general-detail-container__green">
                        <template v-for="(item, index) in greenItems" :key="index">
                            <div class="plan-general-detail-container" :class="item.class || ''">
                                <div class="plan-general-detail-line">
                                    <h5>{{ item.label }}</h5>
                                    <h4 :class="item.itemClass || ''">{{ item.value }}</h4>
                                </div>
                            </div>
                            <div v-if="index < greenItems.length - 1 && hasDivider"
                                :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
                        </template>
                    </div>
                </template>
                <!-- for needed red items -->
                <div v-if="alert && parentIdx === 1"
                    class="plan-general-detail-container__alert plan-general-detail-container__red">
                    <template v-for="(item, index) in parentItem" :key="index">
                        <div class="plan-general-detail-container" :class="item.class || ''">
                            <div class="plan-general-detail-line">
                                <h5>{{ item.label }}</h5>
                                <h4 :class="item.itemClass || ''">{{ item.value }}</h4>
                            </div>
                        </div>
                        <div v-if="index < parentItem.length - 1 && hasDivider"
                            :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
                    </template>
                </div>
                <div v-else class="plan-general-detail-container__alert">
                    <template v-for="(item, index) in parentItem" :key="index">
                        <div class="plan-general-detail-container" :class="item.class || ''">
                            <div class="plan-general-detail-line">
                                <h5>{{ item.label }}</h5>
                                <h4 :class="item.itemClass || ''">{{ item.value }}</h4>
                            </div>
                        </div>
                        <div v-if="index < parentItem.length - 1 && hasDivider"
                            :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
                    </template>
                    <div v-if="parentIdx < items.length - 1 && hasDivider"
                        :class="['divider green-divider w-100', { 'hide-mobile': parentIdx % 2 === 1 }]"></div>
                </div>
            </div>
        </template>

        <div v-else-if="useContainer" class="plan-general-container">
            <template v-for="(item, index) in items" :key="index">
                <div class="plan-general-detail-container" :class="item.class || ''">
                    <div class="plan-general-detail-line">
                        <h5>{{ item.label }}</h5>
                        <h4 :class="item.itemClass || ''">{{ item.value }}</h4>
                    </div>
                </div>
                <div v-if="index < items.length - 1 && hasDivider"
                    :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
            </template>
        </div>

        <template v-else>
            <template v-for="(item, index) in items" :key="index">
                <div class="plan-general-detail-container" :class="item.class || ''">
                    <div class="plan-general-detail-line">
                        <h5>{{ item.label }}</h5>
                        <h4>{{ item.value }}</h4>
                    </div>
                </div>
                <div v-if="index < items.length - 1 && hasDivider"
                    :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: String,
        type: String,
        items: {
            type: Array as () => Array<{ label: string; value: string | number; class?: string }>,
            required: true,
        },
        greenItems: {
            type: Array as () => Array<{ label: string; value: string | number; class?: string }>,
            required: false,
        },
        hasDivider: {
            type: Boolean,
            default: true
        },
        useContainer: {
            type: Boolean,
            default: true
        },
        actionButton: {
            type: String,
            default: 'Update'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        alert: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: null
        }
    },
    setup(_, { emit }) {
        const openModal = () => {
            emit('open-modal');
        }

        return {
            openModal
        }
    },
});
</script>