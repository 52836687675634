<template>
    <Alerts :success="success" :errors="errors" />
    <div class="plan-content">
        <!-- Form -->
        <form id="annuity-application-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Request Type" v-model:selectedOption="requestType" :options="requestTypes"
                :singleArrow="true" :readonly="true"/>
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="formData.participant_id" :readonly="true" />

            <div class="text-input hide-mobile"> </div>

            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="formData.first_name" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="formData.last_name" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="formData.date_of_birth" :readonly="true" />

            <Textarea label="Notes" v-model:modelValue="formData.notes" :max="60" />

            <div class="action-buttons">
                <button @click.prevent="closeModal" class="btn btn-secondary" :disabled="loadingSubmit"> Discard </button>
                <button type="submit" class="btn btn-primary" :disabled="loadingSubmit"> {{ loadingSubmit ? 'Submitting...' : 'Submit' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent ({
    components: {
        Alerts,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund:{
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const requestTypes = [
            { key: 'annuity', value: 'Annuity Application' },
        ];
        const requestType = ref<SelectOption>(requestTypes[0]);

        const formData = reactive({
            type: requestType.value.key,
            participant_id: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            retirement_date: '',
            notes: ''
        });

        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);

        const closeModal = () => {
            emit('close');
        }

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/pension/estimate-request');
                if (response.data) {
                    formData.participant_id = response.data.PersonID;
                    formData.first_name = response.data.FirstName;
                    formData.last_name = response.data.LastName;
                    formData.date_of_birth = response.data.DateOfBirth;
                    loading.value = false;
                    console.log(response.data);
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        };

        const submitData = async () => {
            const route = 'api/participant/pension/estimate-request';
            const method = 'POST'
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit, method });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                closeModal();
            }
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            formData,
            requestType,
            requestTypes,
            success,
            errors,
            loading,
            loadingSubmit,
            submitData,
            closeModal
        }
    }
});
</script>
