<template>
    <!-- Dependent Relationship -->
    <h3> Select Dependent Relationship </h3>
    <SelectComponent customClass="full-width pdt-5 h-auto" v-model:selectedOption="dependentStore.Person.Relationship"
        :options="dependentStore.relationships" :single-arrow="true" :readonly="isEditAction"
        :error="errors.Relationship" :hasDefaultOption=false />

    <!-- Dependent Information -->
    <h3 class="w-100 pdt-15"> Add Dependent Information </h3>
    <SelectComponent label="Country" v-model:selectedOption="dependentStore.Person.CitizenshipCode"
        :options="dependentStore.countries" :single-arrow="true" />
    <TextInput type="text" :name="ssnType" :label="ssnLabel" placeholder="Enter SSN here" :max=ssnMaxLength
        v-model:modelValue="dependentStore.Person.SSN" :required="true" :error="errors.SSN" />

    <div class="w-100 d-flex gap-4">
        <SelectComponent label="Salutation" v-model:selectedOption="dependentStore.Person.Salutation"
            :options="dependentStore.salutations" :single-arrow="true" :hasDefaultOption="true"
            :readonly="isEditAction" />
        <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
            v-model:modelValue="dependentStore.Person.FirstName" :readonly="isEditAction" :required="true"
            :error="errors.FirstName" />
        <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
            v-model:modelValue="dependentStore.Person.MiddleName" :readonly="isEditAction" :error="errors.MiddleName" />
        <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
            v-model:modelValue="dependentStore.Person.LastName" :readonly="isEditAction" :error="errors.LastName" />
        <SelectComponent label="Suffix" v-model:selectedOption="dependentStore.Person.NameSuffix"
            :options="dependentStore.nameSuffixes" :single-arrow="true" :hasDefaultOption="true"
            :readonly="isEditAction" />
    </div>

    <!-- Contact Methods -->
    <TextInput type="email" label="Email" placeholder="Enter Email here" :max=50
        v-model:modelValue="dependentStore.Email" :required="true" />
    <TextInput type="tel" name="phone" label="Phone no" placeholder="Enter Phone Number here" :max=50
        v-model:modelValue="dependentStore.PhoneNumber" :required="true" />
    <DateInput label="Date of Birth" v-model:modelValue="dependentStore.Person.DateofBirth"
        :placeholder="isEditAction ? 'dd/mm/yyyy' : ''" input-type="date" :readonly="isEditAction"
        :error="errors.DateofBirth" />
    <SelectComponent label="Gender" v-model:selectedOption="dependentStore.Person.Sex" :options="dependentStore.genders"
        :single-arrow="true" />

    <SelectComponent v-if="isMaritalStatusVisible" label="Marital Status"
        v-model:selectedOption="dependentStore.Person.MaritalStatus" :options="dependentStore.maritalStatuses"
        :single-arrow="true" :error="errors.MaritalStatus" />
    <DateInput v-if="isMarriageDateVisible" label="Marriage/Affidavit Date"
        v-model:modelValue="dependentStore.Person.MarriageDate" :placeholder="isEditAction ? 'dd/mm/yyyy' : ''"
        input-type="date" :readonly="isEditAction" :error="errors.MarriageDate" />
    <div class="w-50"> </div>

    <!-- Address Fields -->
    <h3 class="w-50"> Address </h3>
    <div v-if="!dependentStore.ShowAddress" class="d-flex gap-4 pdt-30">
        <button type="button" class="btn btn-primary" @click.prevent="handleSetAddress(true)"> Use My Primary
            Address</button>
        <button type="button" class="btn btn-secondary" @click.prevent="handleSetAddress(false)">
            Add New Address
        </button>
    </div>
    <button v-else type="button" class="btn btn-secondary" @click.prevent="handleSetAddress(false, true)">
        Clear Address
    </button>

    <div v-if="dependentStore.ShowAddress" class="form w-100">
        <SelectComponent label="Address Type" v-model:selectedOption="dependentStore.Address.Type"
            :options="profileStore.addressTypes" :single-arrow="true" :hasDefaultOption="true" :error="errors.Type" />
        <TextInput type="text" label="Address" placeholder="Enter Address 1" :max=30
            v-model:modelValue="dependentStore.Address.Street1" :required="true" :error="errors.Street1" />
        <TextInput type="text" label="City" placeholder="Enter City" :max=25
            v-model:modelValue="dependentStore.Address.City" :required="true" :error="errors.City" />

        <SelectComponent label="State" v-model:selectedOption="dependentStore.Address.State" :options="filteredStates"
            :single-arrow="true" />
        <SelectComponent label="Country" v-model:selectedOption="dependentStore.Address.Country"
            :options="profileStore.countries" :single-arrow="true" />

        <TextInput type="text" name="number" label="Postal Code" placeholder="Enter Postal Code" :max=9
            :error="errors.Zip" v-model:modelValue="dependentStore.Address.Zip" :required="true" />
    </div>

    <div class="action-buttons">
        <a href="" @click.prevent="closeModal" class="btn btn-secondary">Back</a>
        <button type="button" class="btn btn-secondary" @click="handleStep2"> Step 2 </button>
    </div>
</template>

<script lang="ts">
import { Ref, ref, defineComponent, computed, watch } from 'vue';
import { useDependentStore, initialAddress } from '@/stores/dependent';
import { useProfileStore } from '@/stores/profile';
import { CountryStates, SelectOption } from '@/interfaces/interfaces';
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

export default defineComponent({
    components: {
        DateInput,
        SelectComponent,
        TextInput,
    },
    setup(_, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const requiredFields = ['Relationship', 'FirstName', 'LastName', 'DateofBirth'];
        const requiredAddressFields = ref<string[]>([]);
        const dependentStore = useDependentStore();
        const profileStore = useProfileStore();
        const isSSN_NAVisible = computed(() => dependentStore.Person?.Relationship?.key == '162');
        const isMaritalStatusVisible = computed(() => dependentStore.Person.Relationship?.key == '181');
        const isMarriageDateVisible = computed(() => dependentStore.Person.MaritalStatus?.key == 1 && dependentStore.Person.Relationship?.key == '181');
        const errors: Ref<{ [key: string]: boolean }> = ref({});
        const isValidStep1 = ref(true);
        const isEditAction = ref(false);
        const ssnLabel = ref('SSN');
        const ssnType = ref('ssn');
        const ssnMaxLength = ref(11);

        watch(() => dependentStore.Person.CitizenshipCode, (newValue) => {
            dependentStore.Person.SSN = '';
            ssnType.value = 'number';
            ssnMaxLength.value = 9;

            if (newValue?.key == 1031) {
                ssnLabel.value = 'SSN';
                ssnType.value = 'ssn';
                ssnMaxLength.value = 11;

            } else if (newValue?.key == 623) {
                ssnLabel.value = 'SIN';
            } else {
                ssnLabel.value = 'OtherID';
            }
        });

        const closeModal = () => {
            emit('close');
        }

        const handleSetAddress = (status: boolean, remove?: boolean) => {
            dependentStore.ShowAddress = true;
            requiredAddressFields.value = ['Type', 'Street1', 'Zip', 'City'];

            if (remove) {
                dependentStore.ShowAddress = false;
                requiredAddressFields.value = [];
                dependentStore.Address = null;
            }

            dependentStore.Address = status ? dependentStore.ParticipantPrimaryAddress : { ...initialAddress };
        }

        const handleStep2 = () => {
            isValidStep1.value = true;

            // Reset all errors to false
            for (const field in errors.value) {
                errors.value[field] = false;
            }

            requiredFields.forEach(field => {
                const value = dependentStore.Person[field as keyof typeof dependentStore.Person];

                if (value === 0 || !value || (typeof value === 'string' && value.trim() === '')) {
                    errors.value[field] = true;
                    isValidStep1.value = false;
                }
            });

            requiredAddressFields.value.forEach(field => {
                const value = dependentStore?.Address ? dependentStore?.Address[field as keyof typeof dependentStore.Address] : null;

                if (!value || (typeof value === 'string' && value.trim() === '')) {
                    errors.value[field] = true;
                    isValidStep1.value = false;
                }
            });

            if (isMaritalStatusVisible.value && !dependentStore.Person.MaritalStatus) {
                errors.value['MaritalStatus'] = true;
                isValidStep1.value = false;
            }

            if (isMarriageDateVisible.value && !dependentStore.Person.MarriageDate) {
                errors.value['MarriageDate'] = true;
                isValidStep1.value = false;
            }

            if (dependentStore.Person.Relationship?.key == '181' && !dependentStore.Person.SSN) {
                errors.value['SSN'] = true;
                isValidStep1.value = false;
            }

            // emit event if is Valid
            if (isValidStep1.value) {
                emit("check-data", isValidStep1.value);
                dependentStore.currentStep = 2;
            }
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = dependentStore.Address?.Country?.key;
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        return {
            errors,
            ssnLabel,
            ssnType,
            ssnMaxLength,
            relationships: dependentStore.relationships,
            profileStore,
            filteredStates,
            isEditAction,
            dependentStore,
            isSSN_NAVisible,
            data: dependentStore.data,
            isMaritalStatusVisible,
            isMarriageDateVisible,
            handleSetAddress,
            handleStep2,
            closeModal,
        }
    }
})
</script>