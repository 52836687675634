<template>
    <div style="width: 100%;">
        <h3>Results</h3>

        <table id="work-history">
            <thead>
                <tr>
                    <th>Work Period</th>
                    <th>Production</th>
                    <th>Employer</th>
                    <th>Local</th>
                    <th>Fund</th>
                    <th>Work Reported</th>
                    <th>Credited</th>
                </tr>
            </thead>
            <tbody>
                <!-- Table with totals and subtotals -->
                <template v-for="(yearDetail, year) in data.Years" :key="year">
                    <template v-for="(result, index) in yearDetail.Results" :key="result.ParticipantWHID">
                        <tr :class="{ 'page-break': (index + 1) % 12 === 0 }">
                            <td>{{ result.WorkPeriod }}</td>
                            <td>{{ result.Production }}</td>
                            <td>{{ result.Employer }}</td>
                            <td>N/A</td>
                            <td>{{ result.Plan }}</td>
                            <td>
                                {{ result.DaysWorked }} Days <br />
                                {{ result.HoursWorked }} Hours <br />
                                {{ formatCurrency(result.EarningsReported) }} Earnings Reported <br />
                                {{ formatCurrency(result.ContractWages) }} Gross Wages
                            </td>
                            <td>{{ result.ContributionsPaid }}</td>
                        </tr>
                        <!-- Row for top page space (temp method) -->
                        <tr v-if="(index + 1) % 12 === 0" class="hidden">
                            <td colspan="7" style="background-color: white; height: 130px;"></td>
                        </tr>
                    </template>

                    <tr class="subtotal">
                        <td colspan="4">
                            <div class="subtotal-cell">Total Contributions for {{ year }}</div>
                        </td>
                        <td>
                            <div class="cell">
                                <p v-for="(amount, fundID) in yearDetail.YearTotals" :key="fundID">
                                    <span v-if="amount > 0">{{ getFundName(fundID) }}</span>
                                </p>
                            </div>
                        </td>
                        <td>
                            <div class="cell">
                                <div v-if="yearDetail.UnitTotals">
                                    <p v-if="yearDetail.UnitTotals.Days > 0">{{ yearDetail.UnitTotals.Days }} Days</p>
                                    <p v-if="yearDetail.UnitTotals.Hours > 0">{{ yearDetail.UnitTotals.Hours }} Hours
                                    </p>
                                    <p v-if="yearDetail.UnitTotals.Earnings > 0">
                                        {{ formatCurrency(yearDetail.UnitTotals.Earnings) }} Earnings
                                    </p>
                                    <p v-if="yearDetail.UnitTotals.Wages > 0">
                                        {{ formatCurrency(yearDetail.UnitTotals.Wages) }} Wages
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cell">
                                <p v-for="(amount, fundID) in yearDetail.YearTotals" :key="fundID">
                                    <span v-if="amount > 0">{{ formatCurrency(amount) }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
                <!-- Grand Total Row -->
                <tr class="total-divider"> </tr>
                <tr class="total">
                    <td colspan="4">
                        <div class="grand-total-cell">Grand Total Contributions</div>
                    </td>
                    <td>
                        <div class="cell">
                            <p v-for="(amount, fundID) in data.GrandTotals" :key="fundID">
                                <span v-if="amount > 0">{{ getFundName(fundID) }}</span>
                            </p>
                        </div>
                    </td>
                    <td>
                        <div class="cell">
                            <div v-if="data.GrandTotalUnits">
                                <p v-if="data.GrandTotalUnits.Days > 0">{{ data.GrandTotalUnits.Days }} Days</p>
                                <p v-if="data.GrandTotalUnits.Hours > 0">{{ data.GrandTotalUnits.Hours }} Hours</p>
                                <p v-if="data.GrandTotalUnits.Earnings > 0">
                                    {{ formatCurrency(data.GrandTotalUnits.Earnings) }} Earnings
                                </p>
                                <p v-if="data.GrandTotalUnits.Wages > 0">
                                    {{ formatCurrency(data.GrandTotalUnits.Wages) }} Wages
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="cell">
                            <p v-for="(amount, fundID) in data.GrandTotals" :key="fundID">
                                <span v-if="amount > 0">{{ formatCurrency(amount) }}</span>
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { WorkHistoryData } from '@/interfaces/interfaces';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useFunds } from '@/composable/useFundsMapping';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<WorkHistoryData>,
            required: true
        }
    },

    setup() {
        const { formatCurrency } = useCurrencyFormatter();
        const { getFundName } = useFunds();

        return {
            formatCurrency, getFundName
        };
    }
});
</script>