<template>
    <Alerts :errors="errors" :success="success" />
    <MessageCard message="If you wish to change your password, email or security question, please do so in the
                        corresponding boxes below." type="danger" iconClass="iatse-icon-alarm"
        classes="info_card_full" />

    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content gap-20">
        <!-- Update Email -->
        <form id="email-change-form" class="form account-settings" @submit.prevent="updateEmail">
            <h4 class="w-100"> Account Email </h4>
            <small class="text-underline"> Account email can be changed in the My Profile page.</small>
            <div class="w-full d-flex gap-3">
                <TextInput type="email" v-model:modelValue="email" label="Email" :placeholder=userEmail :readonly="true"
                    customClass="disabled" />
            </div>
        </form>

        <!-- Update Password -->
        <form id="password-change-form" class="form account-settings" @submit.prevent="updatePassword">
            <h4 class="w-100"> Password </h4>
            <TextInput type="password" label="Current Password" placeholder="Current password"
                v-model:modelValue="currentPassword" />

            <a href="" @click.prevent="verifyPassword" class="btn btn-secondary initial-btn toggle-control"> Update </a>

            <transition name="slide-fade">
                <div class="w-full update-inputs content-toggle" v-if="updateSettingsState.password">
                    <TextInput type="password" label="New Password" placeholder="Enter New password"
                        v-model:modelValue="password" name="password" />

                    <TextInput type="password" label="Confirm New Password" placeholder="Enter Confirm New password"
                        v-model:modelValue="passwordConfirmation" name="password_confirmation" />
                    <div class="w-full">
                        <div class="w-full">
                            <button type="submit" class="btn btn-primary toggle-control"
                                :disabled="loadingUpdate['password']"> Update </button>
                            <a href="" class="btn btn-secondary toggle-control"
                                @click.prevent="toggleUpdateSetting('password')"> Discard </a>
                        </div>
                    </div>
                </div>
            </transition>
        </form>

        <!-- 2 Factor Authentication -->
        <form id="two-fa-form" class="form account-settings" @submit.prevent="setTwoFactorAuth">
            <h4 class="w-100"> Two-Factor Authentication (Required) </h4>
            <a href="" @click.prevent="toggleUpdateSetting('twoFactorAuth')"
                class="btn btn-secondary initial-btn toggle-control"> Setup 2-Factor Authentication </a>

            <transition name="slide-fade">
                <div class="w-full update-inputs content-toggle" v-if="updateSettingsState.twoFactorAuth">
                    <TextInput type="tel" label="Verified Phone numberd" placeholder="******3333" :max=12
                        :modelValue="phoneNumber" />
                    <a href="" class="btn btn-secondary toggle-control"
                        @click.prevent="toggleUpdateSetting('twoFactorAuth')"> Configure </a>
                </div>
            </transition>
        </form>
        <!-- Update Security Questions -->
        <form id="security-questions-form" class="form account-settings" @submit.prevent="updateSecurityQuestion">
            <h4> Security Questions <small v-if="numberOfQuestions > 1"> *Setup at least 3 Security Questions.</small>
            </h4>
            <div v-if="numberOfQuestions > 1" class="w-full">
                <div v-for="index in numberOfQuestions" :key="index">
                    <SecurityQuestionSelect :questions="availableQuestionsForSelect(`select${index}`).value"
                        :userQuestion="userQuestions[index - 1]" label="Security Question"
                        :questionId="userQuestions[index - 1]?.ID" :index="index" @handle-question="handleQuestion"
                        :resetInputs="resetInputs" :options="securityQuestionsArray" :hasMultipleQuestions="true" />
                </div>
            </div>
            <div v-else class="w-full">
                <SecurityQuestionSelect :questions="securityQuestions" :options="securityQuestionsArray"
                    :userQuestion="userQuestions[0]" :selectedQuestions="selectedQuestions" label="Security Question"
                    :questionId="userQuestions[0]?.ID ?? 0" :index="0" @handle-question="handleQuestion"
                    :resetInputs="resetInputs" />
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion, SelectedQuestionType } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import { useAuthStore } from '@/stores/auth';
import axios from 'axios';

import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import TextInput from '@components/form/TextInput.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import Alerts from '@components/utils/AlertsComponent.vue';

type SettingsState = {
    password: boolean;
    email: boolean;
    securityQuestion: boolean;
    twoFactorAuth: boolean;
    newEmail: boolean;
};

type SettingType = keyof SettingsState;

type Error = {
    response: {
        data: {
            errors: string[];
        }
    }
}

export default {
    components: {
        SecurityQuestionSelect,
        TextInput,
        MessageCard,
        Alerts
    },
    setup() {
        const securityQuestions: Ref<SecurityQuestion[]> = ref([]);
        const securityQuestionsArray: Ref<Array<{ key: number, value: string }>> = ref([]);
        const userQuestions: Ref<SecurityQuestion[]> = ref([]);
        const showPassword = ref(false);
        const showPasswordConfirmation = ref(false);
        const currentPassword = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const email = ref('');
        const newEmail = ref('');
        const emailConfirmation = ref('');
        const emailReadOnly = ref(true);
        const phoneNumber = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const isEmployer = useAuthStore().isEmployer;
        const isLocal = useAuthStore().isLocal;
        const userEmail = ref('');
        const numberOfQuestions = isEmployer || isLocal ? 3 : 1;
        const selectedQuestions: Ref<SelectedQuestionType[]> = ref([]);
        const loading = ref(true);
        const resetInputs = ref(false);
        const updateSettingsState = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });

        // Toggle the visibility of editable fields
        const toggleUpdateSetting = (settingType: SettingType) => {
            updateSettingsState.value[settingType] = !updateSettingsState.value[settingType];
        };

        // Togle between hide/show password
        const togglePassword = () => {
            showPassword.value = !showPassword.value;
        }

        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return securityQuestions.value?.filter(q => {
                return !Object.entries(selectedQuestionsId.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.SecurityQuestionID as number);
            });
        })

        // Handle Security Questions Changes
        const handleQuestion = (payload: SelectedQuestion, index: number) => {
            console.log(payload, '@payload');
            selectedQuestionsId.value[`select${index}`] = payload[index].Question;
            selectedQuestions.value = { ...selectedQuestions.value, ...payload };
        }

        // Get Security Questions
        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/user/settings')
                .then(response => {
                    securityQuestions.value = response.data.SecurityQuestions;
                    securityQuestionsArray.value = Object.entries(response.data.SecurityQuestionsArray).map(([key, value]) => ({
                        key: Number(key),
                        value: String(value)
                    }));
                    userEmail.value = response.data.Email;
                    email.value = response.data.Email;

                    if (response.data.UserSecurityQuestions.length > 0) {
                        response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
                            selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
                        });
                    }

                    userQuestions.value = response.data.UserSecurityQuestions;
                    userQuestions.value.map((question: SecurityQuestion) => {
                        const questionObj: SelectedQuestionType = {
                            Answer: "",
                            ExistingQuestion: question.ID,
                            Question: question.SecurityQuestionID as number,
                        }

                        selectedQuestions.value?.push(questionObj);
                    });
                })
                .finally(() => {
                    if (isLoading)
                        loading.value = false;
                });
        };

        // Verify current password
        const verifyPassword = async () => {
            try {
                const verifyResponse = await axios.post('/api/user/verify-password', {
                    current_password: currentPassword.value,
                });

                if (verifyResponse.data.success) {
                    toggleUpdateSetting('password');
                }
            } catch (error: any) {
                if (error.response) {
                    errors.value = Object.values(error.response.data.errors).flat() as string[];
                }

                setTimeout(() => {
                    errors.value = [];
                }, 3500);
            }
        };

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            resetInputs.value = false;
            loadingUpdate.value[settingType] = true;

            axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);

                    setTimeout(() => {
                        if (settingType === 'password') {
                            password.value = '';
                            passwordConfirmation.value = '';
                        } else if (settingType === 'email') {
                            // emailConfirmation.value = '';
                            newEmail.value = '';
                        } else if (settingType === 'securityQuestion') {
                            resetInputs.value = true;
                            selectedQuestions.value = [];
                        }
                    }, 1000);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        errors.value = [];
                        success.value = '';
                    }, 3500);

                    updateSettingsState.value[settingType] = false;
                    loadingUpdate.value[settingType] = false;
                });
        };

        // Password form submit
        const updatePassword = () => {
            const formData = {
                password: password.value,
                password_confirmation: passwordConfirmation.value,
            };

            updateSettings('password', formData);
        };

        // Email form submit
        const updateEmail = () => {
            const formData = {
                email: email.value,
                newEmail: newEmail.value
                // email_confirmation: emailConfirmation.value,
            };

            updateSettings('email', formData);
            emailReadOnly.value = true;
        };

        // Security Question form submit
        const updateSecurityQuestion = () => {
            const formData = {
                security_questions: selectedQuestions.value,
            };

            updateSettings('securityQuestion', formData);
        };

        // Set 2 Factor Authentication
        const setTwoFactorAuth = () => {
            const formData = {
                phoneNumber: phoneNumber.value,
            };
        };

        const addNewEmail = () => {
            updateSettingsState.value['newEmail'] = !updateSettingsState.value['newEmail'];
        }

        onMounted(async () => await fetchData(true));

        return {
            password,
            currentPassword,
            passwordConfirmation,
            newEmail,
            email,
            emailConfirmation,
            phoneNumber,
            showPassword,
            showPasswordConfirmation,
            securityQuestions,
            securityQuestionsArray,
            userQuestions,
            numberOfQuestions,
            isEmployer,
            selectedQuestions,
            userEmail,
            emailReadOnly,
            errors,
            success,
            loading,
            loadingUpdate,
            resetInputs,
            updateSettingsState,
            verifyPassword,
            togglePassword,
            togglePasswordConfirmation,
            handleQuestion,
            updateSettings,
            updatePassword,
            updateEmail,
            updateSecurityQuestion,
            availableQuestionsForSelect,
            toggleUpdateSetting,
            addNewEmail,
            setTwoFactorAuth
        };
    },
}
</script>

<style>
.input-password {
    width: 90%;
}

.reset {
    border: none;
    outline: none;
    align-self: flex-start;
    background-color: transparent;
}

.input-password:focus,
.input-password:active {
    border: none;
    box-shadow: none;
}
</style>