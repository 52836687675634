<template>
    <div class="d-flex justify-content-between mb-4">
        <button class="btn btn-secondary" @click="resetFilters">
            Change my current Filters
        </button>
        <button class="btn btn-primary" @click="printList">Print List</button>
    </div>
    <table class="table table-bordered table-striped" id="table-contribution-history">
        <thead class="table-dark">
            <tr class="text-center align-middle">
                <th colspan="2">Production (multiple)</th>
                <th>Employer (multiple)</th>
                <th>Work Period</th>
                <th>Fund</th>
                <th>Amt. Due $ </th>
                <th>Amt. Paid $ </th>
            </tr>
        </thead>
        <!-- Contributions -->
        <tbody v-if="loadingContributions || loading || changePage">
            <tr class="text-center">
                <td colspan="7">Loading...</td>
            </tr>
        </tbody>
        <tbody v-else-if="!loadingContributions && !loading && history">
            <template v-for="(entry, index) in history" :key="entry.Key">
                <tr class="text-center">
                    <td>
                        <button class="btn btn-sm btn-secondary" @click="toggleDetails(index)">
                            {{ entry.showDetails ? ' - ' : ' + ' }}
                        </button>
                    </td>
                    <td>{{ entry.HistoryItem.Production }}</td>
                    <td>{{ entry.HistoryItem.Employer }}</td>
                    <td>{{ entry.HistoryItem.WorkStart }} - {{ entry.HistoryItem.WorkEnd }}</td>
                    <template v-if="entry.showDetails">
                        <td></td>
                        <td></td>
                        <td></td>
                    </template>
                    <template v-else>
                        <td colspan="3">
                            <div v-for="(fund, fundName) in entry.HistoryItem.Subtotals" :key="fundName"
                                class="d-flex flex-column gap-4">
                                <div class="d-flex gap-5 justify-content-between">
                                    <span>{{ fundName }}</span>
                                    <span>{{ formatNumber(fund.AmountDue) }}</span>
                                    <span>{{ formatNumber(fund.AmountPaid) }}</span>
                                </div>
                            </div>
                        </td>
                    </template>
                </tr>

                <!-- Contribution Details -->
                <tr v-show="entry.showDetails" class="showDetails">
                    <td colspan="10">
                        <table class="table table-striped">
                            <thead class="table-light">
                                <tr class="text-center align-middle">
                                    <th><b>Work Period</b></th>
                                    <th><b>Receipt Date</b></th>
                                    <th><b>Payor</b></th>
                                    <th><b>Fund</b></th>
                                    <th><b>Amount Due $</b></th>
                                    <th><b>Amount Paid $</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in entry.HistoryItem.Items" :key="item.Key"
                                    class="text-center align-middle">
                                    <td>
                                        {{ item.WorkStart }} - {{ item.WorkEnd }}
                                    </td>
                                    <td>{{ item.ReceiptDate }} <br> {{ item.ReportStatus }} </td>
                                    <td>{{ item.Payor }}</td>
                                    <td colspan="3">
                                        <div v-for="(amounts, fundName) in item.Funds" :key="fundName"
                                            class="d-flex flex-column gap-4">
                                            <div class="d-flex gap-4 justify-content-between">
                                                <span>{{ fundName }}</span>
                                                <span>{{ formatNumber(amounts.AmountDue) }}</span>
                                                <span>{{ formatNumber(amounts.AmountPaid) }}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr class="text-center align-middle fw-bold">
                                    <td colspan="3">
                                        Subtotals
                                    </td>
                                    <td colspan="3">
                                        <div v-for="(fund, fundName) in entry.HistoryItem.Subtotals" :key="fundName"
                                            class="d-flex flex-column gap-4">
                                            <div class="d-flex gap-5 justify-content-between">
                                                <span>{{ fundName }}</span>
                                                <span>{{ formatNumber(fund.AmountDue) }}</span>
                                                <span>{{ formatNumber(fund.AmountPaid) }}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </template>

            <tr v-if="paginator">
                <td colspan="7">
                    <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
                </td>
            </tr>

            <!-- Grand Totals -->
            <tr>
                <td colspan="7"></td>
            </tr>
            <template v-if="loadingTotals">
                <tr class="text-center mt-5 fw-bold">
                    <td colspan="7">Loading...</td>
                </tr>
            </template>
            <template v-else>
                <tr v-for="(total, year) in grandTotal?.Year" :key="year" class="text-center mt-5 fw-bold">
                    <td colspan="3">
                        <span>Total for {{ year }}</span>
                    </td>
                    <td colspan="4">
                        <div v-for="(amount, fundName) in total" :key="fundName" class="d-flex flex-column gap-4">
                            <div class="d-flex gap-5 justify-content-between fw-bold">
                                <span>{{ fundName }}</span>
                                <span>$ {{ formatNumber(amount) }}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            <tr class="text-center mt-5 fw-bold">
                <td colspan="3">
                    Grand Total
                </td>
                <td v-if="loadingTotals" colspan="4"> Loading...</td>
                <td v-else colspan="4">
                    <div v-for="(amount, fundName) in grandTotal?.Fund" :key="fundName"
                        class="d-flex flex-column gap-4">
                        <div class="d-flex gap-5 justify-content-between fw-bold">
                            <span>{{ fundName }}</span>
                            <span>$ {{ formatNumber(amount) }}</span>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, Ref, watch, ref, onMounted } from 'vue';
import { History, GrandTotal } from '@/interfaces/local';
import { Paginator } from '@/interfaces/admin';
import { usePrint } from '@/composable/usePrint';
import { useNumberFormat } from '@/composable/useNumberFormat';
import { useContributionsStore } from '@/stores/contributions';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default defineComponent({
    props: {
        loading: Boolean,
        loadingTotals: Boolean,
        pageChanged: Boolean,
        changePage: Boolean,
    },

    components: {
        PaginationComponent,
    },

    setup(props, { emit }) {
        const loadingContributions = ref(true);
        const contributionsStore = useContributionsStore();
        const grandTotal: Ref<GrandTotal | null> = ref(null);
        const history: Ref<History[]> = ref([]);
        const paginator: Ref<Paginator | null> = ref(null);

        const { printElementById } = usePrint();
        const { formatNumber } = useNumberFormat();

        const printList = printElementById('table-contribution-history', 'Contribution History');

        const toggleDetails = (payload: number) => {
            contributionsStore.toggleDetails(payload)
        }

        const resetFilters = () => {
            emit('reset-filters');
        }

        const fetchPaginationData = (pageNumber: number) => {
            emit('fetch-pagination-data', pageNumber);
        }

        const fetchData = () => {
            history.value = contributionsStore.fetchContributions;
            paginator.value = contributionsStore.getPaginator;

            setTimeout(() => loadingContributions.value = false, 1500)
        }

        watch(() => props.loadingTotals, (loadingTotals) => {
            if (!loadingTotals) {
                grandTotal.value = contributionsStore.fetchTotals;
            }
        });

        watch(() => props.pageChanged, (pageChanged) => {
            if (pageChanged) {
                fetchData();
            }
        });

        onMounted(() => fetchData())

        return {
            history,
            paginator,
            grandTotal,
            loadingContributions,
            printList,
            formatNumber,
            resetFilters,
            toggleDetails,
            fetchPaginationData
        }
    }
})

</script>