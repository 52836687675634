import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';

// Set withCredentials globally for Axios
axios.defaults.withCredentials = true;

// Set the base URL from environment variables
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

axios.interceptors.request.use((config) => {
    const authStore = useAuthStore();
    const { token, impersonationToken } = authStore; // Destructure to get the impersonation token

    // Attach the regular token if available
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    // Conditionally attach the impersonation token if available
    if (impersonationToken) {
        // config.headers['X-Impersonation-Token'] = `Bearer ${impersonationToken}`;
        config.headers['X-Impersonation-Token'] = `${impersonationToken}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => {
        // If the response is successful, return it as usual
        return response;
    },
    (error) => {
        const authStore = useAuthStore();
        if (error.response && error.response.status === 401) {
            console.log('401');
            // Clear the auth store if unauthenticated
            authStore.logout(); // Make sure `logout` clears tokens and relevant auth state

            // Redirect to the login page
            router.push({ name: 'Login' });
        }
        return Promise.reject(error); // Reject the error to handle it further if needed
    }
);


export default axios;