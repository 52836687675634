import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-content justify-content-between" }
const _hoisted_2 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_DependentStep1 = _resolveComponent("DependentStep1")!
  const _component_DependentStep2 = _resolveComponent("DependentStep2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      errors: _ctx.errors
    }, null, 8, ["success", "errors"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_MessageCard, {
            message: "Enter the information your spouse/domestic partner and/or each dependent you wish to add.\n        This information must be submitted with documentation regarding their relationship to you (Step 2). Our administrative team will review your request(s) prior making them part of your permanent record.\n        Note that all fields marked with an asterisk are required fields.",
            type: "danger",
            iconClass: "iatse-icon-alarm"
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", _hoisted_2, [
              (!_ctx.isValidStep1)
                ? (_openBlock(), _createBlock(_component_DependentStep1, {
                    key: 0,
                    onCheckData: _ctx.checkData,
                    onClose: _ctx.closeModal
                  }, null, 8, ["onCheckData", "onClose"]))
                : (_openBlock(), _createBlock(_component_DependentStep2, {
                    key: 1,
                    submitting: _ctx.loadingSubmit,
                    onCheckData: _ctx.checkData,
                    onSubmitData: _ctx.handleSubmit
                  }, null, 8, ["submitting", "onCheckData", "onSubmitData"]))
            ])
          ])
        ], 64))
  ], 64))
}