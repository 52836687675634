<template>
    <div class="d-flex flex-column gap-4">
        <h5 class="mb-2">Filters</h5>
        <div class="d-flex flex-column gap-4">
            <div class="d-flex align-items-center gap-4 w-100">
                <span class="col-md-2"> Work Period: <span class="text-danger">*</span> </span>
                <div class="w-50 d-flex align-items-center gap-4">
                    <input type="date" v-model="filters.StartDate" class="form-control w-50" required />
                    <span>-</span>
                    <input type="date" v-model="filters.EndDate" class="form-control w-50" required />
                </div>
            </div>
            <div class="d-flex align-items-center gap-4 w-100">
                <span class="col-md-2"> Employer(s): </span>
                <div class="w-50">
                    <Multiselect v-model="filters.Employers" :options="data.employers" placeholder="Type to search"
                        :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                        @select="handleEmployerChange" label="Name" track-by="ID" @search-change="handleEmployersSearch"
                        @remove="handleProductions" :loading="loading.employers">
                    </Multiselect>
                </div>
            </div>
            <div class="d-flex align-items-center gap-4 w-100">
                <span class="col-md-2"> Production(s) <span v-if="hasEmployers"> for selected
                        Employer(s)</span>: </span>
                <div class="w-50">
                    <Multiselect v-model="filters.Productions" :options="data.productions" placeholder="Type to search"
                        :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                        @select="handleProductionChange" label="Name" track-by="ID" @open="handleProductionsOpen"
                        @search-change="handleProductionsSearch" :loading="loading.productions">
                    </Multiselect>
                </div>
            </div>
            <div class="d-flex align-items-center gap-4 w-100">
                <span class="col-md-2"> Fund(s): </span>
                <div class="w-50">
                    <Multiselect v-model="filters.Funds" :options="data.funds" placeholder="Type to search"
                        :multiple="true" label="Name" track-by="ID" :searchable="true" :close-on-select="true"
                        @select="handleFundChange">
                    </Multiselect>
                </div>
            </div>
            <div class="d-flex align-items-center gap-4 w-100">
                <span class="col-md-2"> Payor(s): </span>
                <div class="w-50">
                    <Multiselect v-model="filters.Payors" :options="data.payors" placeholder="Type to search"
                        :multiple="true" :searchable="true" :close-on-select="true" label="Name" track-by="ID"
                        @select="handlePayorChange" @search-change="handlePayorsSearch" :loading="loading.payors">
                    </Multiselect>
                </div>
            </div>
            <div class="d-flex gap-2 align-self-end w-25">
                <button class="btn btn-secondary" @click="resetFilters">Reset</button>
                <button class="btn btn-primary w-50" @click="searchData">
                    {{ loadingSearch ? 'Searching...' : 'Search' }}</button>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent, reactive, watch, computed, onMounted } from 'vue';
import { ContributionData } from '@/interfaces/local';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

interface Entity {
    ID: string | number;
    Name: string;
}

interface ContributionFilters {
    StartDate?: string;
    EndDate?: string;
    Employers: Entity[] | null;
    Productions: Entity[] | null;
    Funds: Entity[] | null;
    Payors: Entity[] | null;
    Page?: number;
}

export default defineComponent({
    props: {
        reset: Boolean,
        loadingSearch: Boolean,
    },

    components: {
        Multiselect,
    },

    setup(props, { emit }) {
        const allOption: Entity = { ID: 0, Name: 'ALL' };
        const initialFilters = {
            StartDate: '',
            EndDate: '',
            Employers: [allOption],
            Productions: [allOption],
            Funds: [allOption],
            Payors: [allOption],
        };

        const loading = reactive({
            employers: false,
            productions: false,
            payors: false,
            funds: true,
        })

        const data: ContributionData = reactive({
            employers: [],
            productions: [],
            payors: [],
            funds: [],
        })

        const filters: ContributionFilters = reactive({ ...initialFilters })

        watch(() => props.reset, (newValue) => {
            if (newValue) {
                Object.assign(filters, initialFilters);
            }
        });

        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            await handleSelectionSearch('employers', query);
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            await handleSelectionSearch('productions', query);
        };

        // Handle funds search
        const handlePayorsSearch = async (query: string) => {
            await handleSelectionSearch('payors', query);
        };

        const handleFundChange = async (newSelection: Entity) => {
            await handleSelectionChange('Funds', newSelection);
        }

        const handlePayorChange = async (newSelection: Entity) => {
            await handleSelectionChange('Payors', newSelection);
        }

        const handleEmployerChange = async (newSelection: Entity) => {
            await handleSelectionChange('Employers', newSelection);
        }

        const handleProductionChange = async (newSelection: Entity) => {
            await handleSelectionChange('Productions', newSelection);
        }

        // Handle Search in Multiselect
        const handleSelectionSearch = async (type: string, query: string) => {
            loading[type as keyof ContributionData] = true;
            let route = `api/local/${type}?query=${query}`

            if (type === 'productions' || type === 'payors') {
                const employerIds = filters.Employers?.map((employer: Entity) => {
                    if (employer.ID !== 0)
                        return employer.ID
                });

                route += `&&EmployerIDs=${employerIds}`;

                if (type === 'payors') {
                    const productionIDs = filters.Productions?.map((prod: Entity) => {
                        if (prod.ID !== 0)
                            return prod.ID
                    });

                    route += `&&ProductionIDs=${productionIDs}`;
                }
            }

            try {
                const response = await axios.get(route);
                data[type as keyof ContributionData] = response.data;
            }
            catch (error) {
                console.error(`Error searching ${type}:`, error);
            }

            loading[type as keyof ContributionData] = false;
        }

        // Handle Change Selection
        const handleSelectionChange = async (type: string, newSelection: Entity) => {
            if (type === 'StartDate' || type === 'EndDate' || type === 'Page') {
                return;
            }

            let types: Entity[] = newSelection.ID == 0 ? [allOption] : JSON.parse(JSON.stringify(filters[type as keyof typeof filters])).filter((type: Entity) => type.ID != 0);

            if (type === 'Employers') {
                filters.Employers = [...types];

                await handleProductions();
            }

            if (type === 'Productions') {
                filters.Productions = [...types];
            }

            if (type === 'Payors') {
                filters.Payors = [...types];
            }

            if (type === 'Funds') {
                filters.Funds = [...types];
            }
        };

        // Filter selected productions for selected employers
        const handleProductions = async () => {
            await handleProductionsSearch('');

            // if is not selected ALL option, filter selected productions
            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                const filteredProductions = filters.Productions?.filter(prod => data.productions.some(p => p.ID === prod.ID)) || [];
                filters.Productions = filteredProductions;
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Entity) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                await handleProductionsSearch(keyword);
            }
        };

        const fetchData = async () => {
            loading.funds = true;

            try {
                const response = await axios.get('api/local/funds');
                const newData: Entity[] = Object.entries(response.data).map(([ID, value]) => {
                    const Name = value as string;
                    return { ID, Name };
                });

                data.funds = [allOption, ...newData];
            }
            catch (error) {
                console.error(`Error searching funds:`, error);
            }

            loading.funds = false;
        };

        onMounted(async () => await fetchData());

        // Get Contribution History
        const searchData = async () => {
            emit('search', filters);
        }

        // Get Contribution History
        const resetFilters = async () => {
            Object.assign(filters, initialFilters);
        }

        return {
            filters,
            data,
            loading,
            hasEmployers,
            searchData,
            resetFilters,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleFundChange,
            handlePayorChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handlePayorsSearch,
        }
    }
})
</script>