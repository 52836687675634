<template>
    <AlertsComponent :success="success" :errors="errors" />
    <LoaderComponent v-if="loading" />
    <template v-else>
        <MessageCard message="Enter the information your spouse/domestic partner and/or each dependent you wish to add.
        This information must be submitted with documentation regarding their relationship to you (Step 2). Our administrative team will review your request(s) prior making them part of your permanent record.
        Note that all fields marked with an asterisk are required fields." type="danger"
            iconClass="iatse-icon-alarm" />
        <div class="plan-content justify-content-between">
            <form class="form">
                <DependentStep1 v-if="!isValidStep1" @check-data="checkData" @close="closeModal" />
                <DependentStep2 v-else :submitting="loadingSubmit" @check-data="checkData"
                    @submit-data="handleSubmit" />
            </form>
        </div>
    </template>

</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, defineComponent } from 'vue';
import { useDependentStore } from '@/stores/dependent';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';

import DependentStep1 from '@components/participant/dependents/DependentStep1.vue';
import DependentStep2 from '@components/participant/dependents/DependentStep2.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import axios from 'axios';

export default defineComponent({
    components: {
        DependentStep1,
        DependentStep2,
        MessageCard
    },
    setup(_, { emit }) {
        const modalStore = useModalStore();
        const dependentStore = useDependentStore();
        const profileStore = useProfileStore();
        const route = 'api/participant/dependents/add';
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const isValidStep1 = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const isModalVisible = ref(false);
        const openModal = modalStore.openModal;

        const checkData = (isValid: boolean) => {
            isValidStep1.value = isValid;
        }

        const closeModal = () => {
            emit('close');
        }

        const handleSubmit = () => {
            loadingSubmit.value = true;
            const formData = {
                Person: { ...dependentStore.Person, SSN: dependentStore?.Person.SSN ? dependentStore.clearFormatting(dependentStore.Person.SSN) : '' },
                Address: dependentStore.Address,
                Email: dependentStore.Email,
                PhoneNumber: dependentStore?.PhoneNumber ? dependentStore.clearFormatting(dependentStore?.PhoneNumber) : '',
                Documents: dependentStore.Documents,
                IsEditAction: dependentStore.isEditAction,
                ExistingDependent: dependentStore.EditedDependent,
            };

            axios.post(route, { ...formData, _method: "PATCH" })
                .then((response) => {
                    loadingSubmit.value = false;
                    isModalVisible.value = true;

                    if (response.data.success) {
                        openModal('success-modal', null, 'modal-success', null, {
                            title: dependentStore.isEditAction ? 'Dependent Edited' : 'Dependent Added',
                            content: {
                                type: "text",
                                value: dependentStore.Documents.length ? 'The submitted document is currently under review and is pending approval or rejection by the admin' : response.data.success,
                                icon: 'iatse-icon-user-verified'
                            },
                        })

                        dependentStore.fetchDependents();
                        dependentStore.clearDependent();
                    }
                    else {
                        errors.value.push('Something went wrong. Please try again.')
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                }).finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3000);
                })
        };

        const fetchData = async () => {
            await dependentStore.fetchGeneralData();

            if (!profileStore.generalData) {
                await profileStore.fetchGeneralData();
            }

            if (!profileStore.countries || !profileStore.statesByCountry) {
                await profileStore.fetchCountriesStates();
            }

            if (!profileStore.addressTypes.length) {
                await profileStore.fetchAddress();
            }

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            isValidStep1,
            loading,
            success,
            errors,
            isModalVisible,
            loadingSubmit,
            checkData,
            closeModal,
            handleSubmit,
        };
    }
});
</script>